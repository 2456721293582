var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('paymentFees.paymentFees'),"description":_vm.$t('paymentFees.fromHereYouCanControlThePaymentFees')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('paymentFees.SearchForAFee'),"emptyTableText":_vm.$t('paymentFees.thereIsNoPaymentFee'),"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.sales_payments_add || _vm.$user.role.purchase_payments_add  || _vm.$user.role.expenses_add,"cloumns":[
                 {column: 'name',    title:_vm.$t('paymentFees.paymentFeeName') ,type: 'text' , sort: true },
                 {column: 'cost',    title:_vm.$t('paymentFees.paymentFeeCost') ,type: 'text' , sort: true },
                 {column: 'options', title:_vm.$t('paymentFees.actions'), type: 'options', options: [
                     {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.sales_payments_edit || _vm.$user.role.purchase_payments_edit  || _vm.$user.role.expenses_edit},
                     {name: 'delete', role: _vm.$user.admin || _vm.$user.role.sales_payments_delete || _vm.$user.role.purchase_payments_delete  || _vm.$user.role.expenses_delete} ]}
               ],"deleteText":{
                attention: _vm.$t('allerts.Attention'),
                areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                close: _vm.$t('allerts.close'),
                confirm: _vm.$t('allerts.confirm'),
                loading: _vm.$t('allerts.loading'),
               }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }