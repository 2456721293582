var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.Projects'),"description":_vm.$t('mainReport.FromhereyoucancontrolyourProjectsreports')}})],1),(
        (
          _vm.$user.role.available_reports.find(function (el) { return el.id == 38 || el.id == 39 || el.id == 40 || el.id == 41; }) || _vm.$user.admin
        ) && _vm.$site.projects_allow 
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.Projects'),"icon":'fa-chart-line',"reports":[
          {
            name: _vm.$t('mainReport.TotalProjectAccount'),
            link: './projectsTotalAccounts',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 38; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.ProjectAccountDetails'),
            link: '/projectsDetailsAccounts',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 39; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.ProjectAssets'),
            link: '/projectsBalance',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 40; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.AGeneralGlimpseOfTheProject'),
            link: '/projectsOverview',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 41; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.projectMovement'),
            link: '/projectMovement',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 41; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 46 || el.id == 47; }
          ) || _vm.$user.admin
        )&& _vm.$site.projectsDeposits_allow 
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.ProjectFilings'),"icon":'fa-credit-card-front',"reports":[
          {
            name: _vm.$t('mainReport.InvoiceDeposits'),
            link: '/projectsDepositsByInvoice',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 46; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.ProductDeposits'),
            link: '/projectsDepositsByItem',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 47; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 48 || el.id == 49; }
          ) || _vm.$user.admin
        )&&  _vm.$site.projectsWithdrawals_allow 
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.ProjectWithdrawals'),"icon":'fa-credit-card-front',"reports":[
          {
            name: _vm.$t('mainReport.WithdrawalsByInvoice'),
            link: './projectsWithdrawalsByBill',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 48; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.ProductWithdrawals'),
            link: '/projectsWithdrawalsByItem',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 49; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 50 || el.id == 51; }
          ) || _vm.$user.admin
        )&& _vm.$site.projectsTransfers_allow
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.ProjectTransfers'),"icon":'fa-exchange',"reports":[
          {
            name: _vm.$t('mainReport.InvoiceTransfers'),
            link: '/projectsTransfersByInvoice',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 50; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.ProductTransfers'),
            link: '/projectsTransfersByItem',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 51; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }