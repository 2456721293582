import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import axios from 'axios'
import { i18n } from './i18n'


import './print/print'
import './functions'
import './option'
import './user'
import './database'
import './localDatabase'
import './tables'
import './deliveries'
import './users'
import './posInvoices'

Vue.config.productionTip = false
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
//axios.defaults.headers.common['Authorization'] = 'sokoaskosa';
Vue.prototype.$mainSite = 'rowaderp.com'
import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);


new Vue({
  router,
  i18n ,
  render: h => h(App),
}).$mount('#app')