const routes =  [

  //suppliers routes
    { 
      path: "suppliers", 
      name: "suppliers",
      component: function () {
        return import( '@/accounting/suppliers/index.vue')
      }
    },
    {
      path: "suppliers/create",
      name: "suppliersCreate",
      component: function () {
        return import( '@/accounting/suppliers/form.vue')
      }
    },
    {
      path: "suppliers/:id/edit",
      name: "suppliersEdit",
      component: function () {
        return import( '@/accounting/suppliers/form.vue')
      }
    },
    {
      path: "suppliers/explaination",
      name: "suppliersExplaination",
      component: function () {
        return import( '@/accounting/suppliers/explain.vue')
      }
    },
    {
      path: "suppliers/:id",
      name: "suppliersShow",
      component: function () {
        return import( '@/accounting/suppliers/show.vue')
      }
    },

    //bills routes

    { 
      path: "bills",
      name: "bills",
      component: function () {
        return import( '@/accounting/bills/index.vue')
      }
    },
    { 
      path: "bills/create", 
      name: "billsCreate",
      component: function () {
        return import( '@/accounting/bills/form.vue')
      }
    },
    { 
      path: "bills/:id/edit",
      name: "billsEdit",
      component: function () {
        return import( '@/accounting/bills/form.vue')
      }
    },
    { path: "bills/:id",
      name: "billsShow",
      component: function () {
        return import( '@/accounting/bills/show.vue')
      }
    },

    //purchaseReturns routes

    {
      path: "purchaseReturns",
      name: "purchaseReturns",
      component: function () {
        return import( '@/accounting/purchaseReturns/index.vue')
      }
    },
    {
      path: "purchaseReturns/create",
      name: "purchaseReturnsCreate",
      component: function () {
        return import( '@/accounting/purchaseReturns/form.vue')
      }
    },
    {
      path: "purchaseReturns/:id/edit",
      name: "purchaseReturnsEdit",
      component: function () {
        return import( '@/accounting/purchaseReturns/form.vue')
      }
    },
    {
      path: "purchaseReturns/:id",
      name: "purchaseReturnsShow",
      component: function () {
        return import( '@/accounting/purchaseReturns/show.vue')
      }
    },

    //purchaseOrders routes

    {
      path: "purchaseOrders",
      name: "purchaseOrders",
      component: function () {
        return import( '@/accounting/purchaseOrders/index.vue')
      }
    },
    {
      path: "purchaseOrders/create",
      name: "purchaseOrdersCreate",
      component: function () {
        return import( '@/accounting/purchaseOrders/form.vue')
      }
    },
    {
      path: "purchaseOrders/:id/edit",
      name: "purchaseOrdersEdit",
      component: function () {
        return import( '@/accounting/purchaseOrders/form.vue')
      }
    },
    {
      path: "purchaseOrders/:id",
      name: "purchaseOrdersShow",
      component: function () {
        return import( '@/accounting/purchaseOrders/show.vue')
      }
    },

];

export default routes;
