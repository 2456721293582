<template>
    <div class="row container-fluid">
      <div class="col-12">
        <indexHeader 
          :title="$t('mainReport.partners')"
          :description="$t('mainReport.Fromhereyoucancontrolyourpartnersreports')"/>
        </div>

          <!-- تقارير الشركاء -->
          <reportsCard
            class="col-md-6"
            :cardTitle="$t('mainReport.partners')"
            :icon="'fa-handshake'"
            v-if="
              (
                $user.role.available_reports.find(
                  (el) => el.id == 34 || el.id == 35 || el.id == 36 || el.id == 37
                ) || $user.admin
              )
              && $site.partners_allow
            "
            :reports="[
              {
                name: $t('mainReport.TotalPartnerAccount'),
                link: './partnersTotalAccounts',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 34
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.PartnerAccountDetails'),
                link: '/partnersDetailsAccounts',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 35
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.PartnersAssets'),
                link: '/partnersBalance',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 36
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.PartnerGuide'),
                link: '/partnersGuide',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 37
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.partnerMovement'),
                link: '/partnerMovement',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 37
                  ) || $user.admin,
              },
            ]"
          />
          <!-- تقارير عمليات الشركاء -->

          <reportsCard
            class="col-md-6"
            :cardTitle="$t('mainReport.PartnersOperations')"
            :icon="'fa-handshake'"
            v-if="
            (
              $user.role.available_reports.find(
                (el) => el.id == 31 || el.id == 32
              ) || $user.admin
            )&& 
            ($site.partnersDeposits_allow ||
                $site.partnersWithdrawals_allow ||
                $site.partnersSettlements_allow ||
                $site.partnersProfits_allow) 
            "
            :reports="[
              {
                name: $t('mainReport.PartnerDepositsHistory'),
                link: './partnerDepositsHistory',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 31
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.PartnerWithdrawalsHistory'),
                link: '/partnerWithdrawalsHistory',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 32
                  ) || $user.admin,
              },
            ]"
          />
        </div>
</template>
<script>
import reportsCard from "../elements/reports/reportsCard.vue";
import indexHeader from'../elements/index/indexHeader.vue';
export default {
    components: {
    reportsCard,
    indexHeader,
  },
}
</script>