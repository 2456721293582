var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('signatures.signatures'),"description":_vm.$t('signatures.fromControlYourLights')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('signatures.search'),"emptyTableText":_vm.$t('signatures.thereSignatures'),"buttonRole":_vm.$user.role.products_add,"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                   {column: 'name',    title:_vm.$t('signatures.jobTitle') ,type: 'text' , sort: true },
                   {column: 'options', title:_vm.$t('sections.settings'), type: 'options', options: [
                       {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.signatures_edit},
                       {name: 'delete', role: _vm.$user.admin || _vm.$user.role.signatures_delete} ]}
                 ],"deleteText":{
                  attention: _vm.$t('allerts.Attention'),
                  areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                  close: _vm.$t('allerts.close'),
                  confirm: _vm.$t('allerts.confirm'),
                  loading: _vm.$t('allerts.loading'),
                 }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }