var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('reports.accounts.reports'),"description":_vm.$t('reports.overview.theMostActiveCustomers')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('reports.accounts.reportOptions'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.customer'),"plaseholder":_vm.$t('reports.overview.chooseCustomer'),"show":'name',"refrance":'id',"value":_vm.params.customer_id,"values":_vm.$database.customers},model:{value:(_vm.params.customer_id),callback:function ($$v) {_vm.$set(_vm.params, "customer_id", $$v)},expression:"params.customer_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.sectionCustomer'),"plaseholder":_vm.$t('reports.overview.chooseSection'),"show":'name',"refrance":'id',"value":_vm.params.sections_account_id,"values":_vm.$database.sectionsAccounts},model:{value:(_vm.params.sections_account_id),callback:function ($$v) {_vm.$set(_vm.params, "sections_account_id", $$v)},expression:"params.sections_account_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('reports.overview.theMostActiveCustomers'),"details":[
            {title: _vm.$t('reports.overview.customerName'), value : _vm.customerName },
            {title: _vm.$t('reports.overview.sectionCustomer'), value : _vm.sectionCustomerName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('reports.accounts.thereNoDataTheMoment'),"emptyTableSubText":_vm.$t('reports.accounts.tryAddingSomeAndTryAgain'),"cloumns":[
            {
              column: 'name',
              link: '/customers',
              title:_vm.$t('customers.customerName') ,
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'mobile',
              title: _vm.$t('reports.overview.phoneNumber'),
              type: 'text',
              sort: 'true',
            },
            {
              column: 'total',
              title: _vm.$t('reports.overview.treatment'),
              type: 'text',
              sort: 'true',
            } ],"footer":[
            { colspan: 2, value: _vm.$t('reports.overview.total') },
            { colspan: 1, value: _vm.total} ]}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }