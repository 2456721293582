var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.pointOfSale'),"description":_vm.$t('mainReport.FromhereyoucancontrolyourpointOfSalereports')}})],1),(
        (
          _vm.$user.role.available_reports.find(function (el) { return el.id == 0; }) ||
          _vm.$user.admin
        )&&  _vm.$site.pointOfSales_allow
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.pointOfSale'),"icon":'far fa-cash-register',"reports":[
          {
            name: _vm.$t('mainReport.pointOfSale'),
            link: './pointOfSalesOverview',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 0; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }