<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="'التقارير'"
          :description="'تقارير حركة المشروعات '"
        />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              خيارات التقرير
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                title="اسم المشروع"
                plaseholder="أختر اسم المشروع"
                v-model="params.projects_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.projects_id"
                :values="$database.projects"
              />
              <div class="form-group col-md-3">
                <label> عرض الأرصدة الصفرية </label>
                <div class="custom-control custom-switch small-switch">
                  <input
                    type="checkbox"
                    id="zero_balance_allow"
                    v-model="params.zero_balance_allow"
                    class="custom-control-input"
                    :checked="params.zero_balance_allow"
                  />
                  <label
                    for="zero_balance_allow"
                    class="custom-control-label"
                  />
                </div>
              </div>
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
        <reportHeader 
            title="حركة المشروع  " 
            :details="[
              {title: 'اسم المشروع', value : projectName },
            ]"
          />
          <reportTable
            :emptyTableText="'لايوجد بيانات حاليا'"
            :emptyTableSubText="'جرب اضافة البعض واعد المحاولة'"
            :cloumns="[
              { column: 'name',link: '/projects' , title: 'اسم المشروع', type:'reportMainLink', sort: true},
              { column: 'debit', title: 'حركه دائنة', type: 'text', sort: true },
              { column: 'creditor', title: 'حركه مدينة', type: 'text', sort: true },
              { column: 'balance', title: 'الرصيد', type: 'text' ,sort: true},
            ]"
            :footer="[
              { colspan: 1, value: $t('reports.overview.total') },
              { colspan: 1, value: debit },
              { colspan: 1, value: creditor },
              { colspan: 1, value: balance },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import reportHeader from "../../elements/reports/reportHeader.vue";
import reportTable from "../../elements/reports/reportTable.vue";
import indexHeader from "../../elements/index/indexHeader.vue";
import dateFilter from "../../elements/reports/dateFilter.vue";
import searchInput from "../../elements/reports/searchInput.vue";
import actionButtons from "../../elements/reports/actionButtons.vue";

export default {
  data() {
    return {
      path: "/reports/projects/projectMovement",
      items: [],
      loading: 1,

      debit: 0,
      creditor: 0,
      balance: 0,

      params: {
        project_id: "",
        zero_balance_allow: ""
      },

      excel: {
        fileName: 'project Movement',
        reportData: '[]'
      },

      projectName: ""
    };
  },
  methods: {
    getData() {
      this.$setParams(this.params);
      this.loading = 1;
      this.totalPages = 0;
      this.items = [];
      axios
        .get(this.$linkGnirator(this.path), { params: this.params })
        .then((response) => {
          let data = response.data;

          this.items = []

          this.debit = 0;
          this.creditor = 0;
          this.balance = 0;

          let itemss = JSON.parse(JSON.stringify(this.$database.projects))
          itemss.forEach(project => {
            project.projectsDeposits    = data.projectsDeposits.filter(el => el.project_id == project.id).map(item => item.total).reduce((prev, next) => prev + next, 0)
            project.projectsWithdrawals = data.projectsWithdrawals.filter(el => el.project_id == project.id).map(item => item.total).reduce((prev, next) => prev + next, 0)
            project.salesPayments       = data.salesPayments.filter(el => el.project_id == project.id).map(item => item.cost).reduce((prev, next) => prev + next, 0)
            project.expenses            = data.expenses.filter(el => el.project_id == project.id).map(item => item.cost).reduce((prev, next) => prev + next, 0)
            project.partnersSettlements = data.partnersSettlements.filter(el => el.project_id == project.id).map(item => item.total).reduce((prev, next) => prev + next, 0)
            project.partnersProfits     = data.partnersProfits.filter(el => el.project_id == project.id).map(item => item.total).reduce((prev, next) => prev + next, 0)
          
            
            project.debit = 
            project.projectsDeposits +
            project.expenses +
            project.partnersProfits 

            project.creditor = 
            project.salesPayments +
            project.projectsWithdrawals +
            project.partnersSettlements

            this.items.push(project)
          });

          if (this.params.sections_account_id) {
            this.items = this.items.filter(el => el.sections_account_id == this.params.sections_account_id )
          }
          if (!this.params.zero_balance_allow) {
            this.items = this.items.filter(el => el.balance != 0)
          }
          
          this.items.forEach((item) => {
            if (item.opening_balance < 0) {
                item.debit += item.opening_balance
            } else if (item.opening_balance > 0) {
              item.creditor += item.opening_balance
            }


            this.debit    += parseFloat(item.debit);
            this.creditor += parseFloat(item.creditor);
            this.balance  += parseFloat(item.balance);

            item.debit    = this.$moneyFormat(item.debit)
            item.creditor = this.$moneyFormat(item.creditor)
            item.balance  = this.$moneyFormat(item.balance)
          });

          var projects = this.$database.projects.find((el) => el.id == this.params.projects_id);
          if (projects) {
            this.projectsName = projects.name;
          } else {
            this.projectsName = "";
          }
          
          this.debit    = this.$moneyFormat(this.debit)
          this.creditor = this.$moneyFormat(this.creditor)
          this.balance  = this.$moneyFormat(this.balance)
          this.loading = 0;
        });
    }
  },
  watch: {
    items : function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data  = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map(r => [...r.querySelectorAll('th, td')].map(td => td.textContent))
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData);
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams();
    this.$updateDatabase(["projects"]);
    this.getData();
  },
  components: {
    reportHeader,
    reportTable,
    indexHeader,
    dateFilter,
    searchInput,
    actionButtons,
  },
};
</script>
