var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'سحب بالفاتورة.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المخزن","plaseholder":"اختر مخزن","show":'name',"refrance":'id',"value":_vm.params.store_id,"values":_vm.$database.stores},model:{value:(_vm.params.store_id),callback:function ($$v) {_vm.$set(_vm.params, "store_id", $$v)},expression:"params.store_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"تقرير المسحوبات بالفاتورة","details":[
            {title: 'اسم المستخدم', value : _vm.userName },
            {title: ' مخزن', value : _vm.storeName } ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            { column: 'code', link: '/bills', title: 'الكود', type:'reportMainLink', sort: true},
            { column: 'store_id',    title: _vm.$t('storesDeposits.storeName'), type: 'link', to:'store', sort: true, link: true},
            { column: 'date', title: 'التاريخ', type: 'text', sort: true },
            { column: 'client_type', title: 'اسم المورد', type: 'clintLink', sort: false },
            { column: 'user', title: 'المستخدم', type: 'text', sort: true },
            { column: 'total', title: 'المجموع', type: 'text', sort: true } ],"footer":[
            { colspan: 1, value: 'المجموع' },
            { colspan: 3, value: _vm.invoicesCount },
            { colspan: 1, value: _vm.total } ]}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])}]

export { render, staticRenderFns }