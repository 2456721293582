var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'المنتجات الغير نشطة'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المنتج","plaseholder":"اختر المنتج","show":'name',"refrance":'id',"value":_vm.params.customer_id,"values":_vm.$database.customers},model:{value:(_vm.params.customer_id),callback:function ($$v) {_vm.$set(_vm.params, "customer_id", $$v)},expression:"params.customer_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"المنتجات الغير النشطه","details":[
            {title: 'اسم المنتج', value : _vm.customerName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            {
              column: 'name',
              link: '/customers',
              title: 'اسم المنتج' ,
              type: 'reportMainLink' ,
              sort: true
            },
            {
              column: 'balance',
              title: 'الكميه' ,
              type: 'text' ,
              sort: true
            },
            {
              column: 'date',
              title: 'تاريخ اخر معامله',
              type: 'text',
              sort: true
            } ]}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])}]

export { render, staticRenderFns }