var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.Discounts'),"description":_vm.$t('mainReport.FromhereyoucancontrolyourDiscountsreports')}})],1),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 29 || el.id == 30; }
          ) || _vm.$user.admin
        )&& 
        (_vm.$site.salesDiscounts_allow ||
            _vm.$site.purchaseDiscounts_allow) &&
          (_vm.$user.admin ||
            _vm.$user.role.sales_discounts_show ||
            _vm.$user.role.purchase_discounts_show)
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.Discounts'),"icon":'fa-badge-percent',"reports":[
          {
            name: _vm.$t('mainReport.RecordSalesDiscounts'),
            link: './discountsHistory',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 29; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.RecordPurchaseDiscounts'),
            link: '/purchaseDiscountsHistory',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 30; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }