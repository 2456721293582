var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('paymentMethods.paymentMethods'),"description":_vm.$t('paymentMethods.fromHereYouCanControlThePaymentMethods')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('paymentMethods.SearchForAPaymentMethod'),"emptyTableText":_vm.$t('paymentMethods.thereAreNoPaymentMethods'),"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.sales_payments_add || _vm.$user.role.purchase_payments_add  || _vm.$user.role.expenses_add ? 1 : 0,"cloumns":[
                   {column: 'name',    title:_vm.$t('paymentMethods.paymentMethod') ,type: 'text' , sort: true },
                   {column: 'options', title:_vm.$t('paymentMethods.actions'), type: 'options', options: [
                   {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.sales_payments_edit || _vm.$user.role.purchase_payments_edit  || _vm.$user.role.expenses_edit},
                   {name: 'delete', role: _vm.$user.admin || _vm.$user.role.sales_payments_delete || _vm.$user.role.purchase_payments_delete  || _vm.$user.role.expenses_delete} ]}
                 ],"deleteText":{
                  attention: _vm.$t('allerts.Attention'),
                  areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                  close: _vm.$t('allerts.close'),
                  confirm: _vm.$t('allerts.confirm'),
                  loading: _vm.$t('allerts.loading'),
                 }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }