var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid main-show"},[(!_vm.loaded)?_c('mainLoader'):_vm._e(),(_vm.loaded)?_c('div',{staticClass:"row mt-3"},[(
        _vm.$site.reports_allow &&
        (
        (_vm.$option.main_elements.find(function (el) { return el.id == 1; })  && _vm.$user.admin) ||
        (_vm.$user.role.main_elements.find(function (el) { return el.id == 1; }))
        ))?_c('billsAndPayment',{staticClass:"col-12 col-lg-6",attrs:{"items":_vm.data.billsAndPayment,"lastYierItems":_vm.data.billsAndPaymentLastYier}}):_vm._e(),(
        (_vm.$option.main_elements.find(function (el) { return el.id == 8; }) && _vm.$user.admin) ||
        (_vm.$user.role.main_elements.find(function (el) { return el.id == 8; }))
        )?_c('urgentActions',{staticClass:"col-12 col-lg-6"}):_vm._e(),(
        _vm.$site.reports_allow &&
        (
        (_vm.$option.main_elements.find(function (el) { return el.id == 2; }) && _vm.$user.admin) ||
        (_vm.$user.role.main_elements.find(function (el) { return el.id == 2; }))
        ))?_c('fastStats',{staticClass:"col-12 ",attrs:{"items":_vm.data.fastStats,"lastMonthItems":_vm.data.fastStatsLastMonth}}):_vm._e(),(
        _vm.$site.customers_allow &&
        (
        (_vm.$option.main_elements.find(function (el) { return el.id == 14; }) && _vm.$user.admin) ||
        (_vm.$user.role.main_elements.find(function (el) { return el.id == 14; }))
        )
        )?_c('customersBalance',{staticClass:"col-12 col-lg-6",attrs:{"items":_vm.data.customersBalance}}):_vm._e(),(
        _vm.$site.stores_allow &&
        (
        (_vm.$option.main_elements.find(function (el) { return el.id == 3; }) && _vm.$user.admin) ||
        (_vm.$user.role.main_elements.find(function (el) { return el.id == 3; }))
        ))?_c('storeAlerts',{staticClass:"col-12 col-lg-6",attrs:{"items":_vm.data.storeAlerts}}):_vm._e(),(
        _vm.$site.customers_allow &&
        (
        (_vm.$option.main_elements.find(function (el) { return el.id == 10; }) && _vm.$user.admin) ||
        (_vm.$user.role.main_elements.find(function (el) { return el.id == 10; }))
        )
        )?_c('theMostActiveClients',{staticClass:"col-12 col-lg-6",attrs:{"items":_vm.data.theMostActiveClients}}):_vm._e(),(
        _vm.$site.expenses_allow &&
        (
        (_vm.$option.main_elements.find(function (el) { return el.id == 9; }) && _vm.$user.admin) ||
        (_vm.$user.role.main_elements.find(function (el) { return el.id == 9; }))
        )
        )?_c('theHighestExpenses',{staticClass:"col-12 col-lg-6",attrs:{"items":_vm.data.theHighestExpenses}}):_vm._e(),(
        _vm.$site.reports_allow &&
        (
        (_vm.$option.main_elements.find(function (el) { return el.id == 6; }) && _vm.$user.admin) ||
        (_vm.$user.role.main_elements.find(function (el) { return el.id == 6; }))
        ))?_c('unpaidInvoices',{staticClass:"col-12 col-lg-6",attrs:{"items":_vm.data.unpaidInvoices}}):_vm._e(),(
        _vm.$site.invoices_allow &&
        (
        (_vm.$option.main_elements.find(function (el) { return el.id == 12; }) && _vm.$user.admin) ||
        (_vm.$user.role.main_elements.find(function (el) { return el.id == 12; }))
        ))?_c('recentInvoices',{staticClass:"col-12 col-lg-6",attrs:{"items":_vm.data.recentInvoices}}):_vm._e(),(_vm.$user.admin|| _vm.$user.role.reports_show)?_c('overview',{staticClass:"col-12 ",attrs:{"items":_vm.data.overview}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }