var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.shippingOperations'),"description":_vm.$t('mainReport.FromhereyoucancontrolyourshippingOperationsreports')}})],1),(
      (
        _vm.$user.role.available_reports.find(
          function (el) { return el.id == 13 || el.id == 14; }
        ) || _vm.$user.admin
      )&& _vm.$site.cities_allow 
      )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.shippingOperations'),"icon":'far fa-shipping-fast',"reports":[
        {
          name: _vm.$t('mainReport.shippingOperations'),
          link: './shippingOperationsByInvoice',
          role:
            _vm.$user.role.available_reports.find(
              function (el) { return el.id == 13; }
            ) || _vm.$user.admin,
        } ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }