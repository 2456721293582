const routes =  [
    { 
      path: "stores", 
      name: "stores",
      component: function () {
        return import( '../accounting/stores/index.vue')
      }
    },
    {
      path: "stores/create",
      name: "storesCreate",
      component: function () {
        return import( '../accounting/stores/form.vue')
      }
    },
    {
      path: "stores/:id/edit",
      name: "storesEdit",
      component: function () {
        return import( '../accounting/stores/form.vue')
      }
    },
    {
      path: "stores/explaination",
      name: "storesExplaination",
      component: function () {
        return import( '../accounting/stores/explain.vue')
      }
    },
    {
      path: "stores/:id",
      name: "storesShow",
      component: function () {
        return import( '../accounting/stores/show.vue')
      }
    },

    //products routs
      { 
        path: "products", 
        name: "products", 
        component: function () {
          return import( '../accounting/products/index.vue')
        }
      },
      {
        path: "products/create",
        name: "productsCreate",
        component: function () {
          return import( '../accounting/products/form.vue')
        }
      },
      {
        path: "products/:id/edit",
        name: "productsEdit",
        component: function () {
          return import( '../accounting/products/form.vue')
        }
      },
      {
        path: "products/explaination",
        name: "productsExplaination",
        component: function () {
          return import( '../accounting/products/explain.vue')
        }
      },
      { 
        path: "products/:id", 
        name: "productsShow", 
        component: function () {
          return import( '../accounting/products/show.vue')
        }
      },
      { 
        path: "productsBarcode", 
        name: "productsBarcode", 
        component: function () {
          return import( '../accounting/products/barcode.vue')
        } 
      },
      { 
        path: "goldBarcode", 
        name: "goldBarcode", 
        component: function () {
          return import( '../accounting/products/goldBarcode.vue')
        }  
      },
      {
        path: "productsBarcode/bill/:id",
        name: "productsBarcodeBill",
        component: function () {
          return import( '../accounting/products/barcode.vue')
        }  
      },

      //materials routs
      { 
        path: "materials", 
        name: "materials", 
        component: function () {
          return import( '../accounting/materials/index.vue')
        }  
      },
      {
        path: "materials/create",
        name: "materialsCreate",
        component: function () {
          return import( '../accounting/materials/form.vue')
        }
      },
      {
        path: "materials/:id/edit",
        name: "materialsEdit",
        component: function () {
          return import( '../accounting/materials/form.vue')
        }
      },
      {
        path: "materials/explaination",
        name: "materialsExplaination",
        component: function () {
          return import( '../accounting/materials/explain.vue')
        }
      },
      {
        path: "materials/:id",
        name: "materialsShow",
        component: function () {
          return import( '../accounting/materials/show.vue')
        }
      },
      
      //storesDeposits routes
      {
        path: "storesDeposits",
        name: "storesDeposits",
        component: function () {
          return import( '../accounting/storesDeposits/index.vue')
        }
      },
      {
        path: "storesDeposits/create",
        name: "storesDepositsCreate",
        component: function () {
          return import( '../accounting/storesDeposits/form.vue')
        }
      },
      {
        path: "storesDeposits/:id/edit",
        name: "storesDepositsEdit",
        component: function () {
          return import( '../accounting/storesDeposits/form.vue')
        }
      },
      {
        path: "storesDeposits/:id",
        name: "storesDepositsShow",
        component: function () {
          return import( '../accounting/storesDeposits/show.vue')
        }
      },
      //storesWithdrawals routes
      {
        path: "storesWithdrawals",
        name: "storesWithdrawalsIndex",
        component: function () {
          return import( '../accounting/storesWithdrawals/index.vue')
        }
      },
      {
        path: "storesWithdrawals/create",
        name: "storesWithdrawalsCreate",
        component: function () {
          return import( '../accounting/storesWithdrawals/form.vue')
        }
      },
      {
        path: "storesWithdrawals/:id/edit",
        name: "storesWithdrawalsEdit",
        component: function () {
          return import( '../accounting/storesWithdrawals/form.vue')
        }
      },
      {
        path: "storesWithdrawals/:id",
        name: "storesWithdrawalsShow",
        component: function () {
          return import( '../accounting/storesWithdrawals/show.vue')
        }
      },

      //storesTransfers routes
      {
        path: "storesTransfers",
        name: "storesTransfers",
        component: function () {
          return import( '../accounting/storesTransfers/index.vue')
        }
      },
      {
        path: "storesTransfers/create",
        name: "storesTransfersCreate",
        component: function () {
          return import( '../accounting/storesTransfers/form.vue')
        }
      },
      {
        path: "storesTransfers/:id/edit",
        name: "storesTransfersEdit",
        component: function () {
          return import( '../accounting/storesTransfers/form.vue')
        }
      },
      {
        path: "storesTransfers/:id",
        name: "storesTransfersShow",
        component: function () {
          return import( '../accounting/storesTransfers/form.vue')
        }
      },
];

export default routes;
