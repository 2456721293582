var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'اجمالي حساب الشريك.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"الشريك","plaseholder":"اختر شريك","show":'name',"refrance":'id',"value":_vm.params.partner_id,"values":this.$database.partners.filter(function (el) { return el.stat == 1; })},model:{value:(_vm.params.partner_id),callback:function ($$v) {_vm.$set(_vm.params, "partner_id", $$v)},expression:"params.partner_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"اجمالي حساب الشريك","details":[
            {title: 'اسم الشريك', value : _vm.partnerName }
          ]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 6, value: 'الرصيد الأفتتاحي' },
            { colspan: 1, value: _vm.startBalance} ],"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            { column: 'code', title: 'الكود', type: 'reportCode' },
            { column: 'date', title: 'التاريخ', type: 'text' },
            { column: 'type', title: 'العملية', type: 'reportType' },
            { column: 'statement', title: _vm.$t('reports.overview.statement'), type: 'statement' },
            { column: 'debit', title: 'مدين', type: 'textOrEmpty' },
            { column: 'creditor', title: 'دائن', type: 'textOrEmpty' },
            { column: 'new_balance', title: 'الرصيد', type: 'text' } ],"footer":[
            { colspan: 4, value: 'الرصيد النهائي' },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            { colspan: 1, value: _vm.endBalance } ],"tafqeet":_vm.tafqeet}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])}]

export { render, staticRenderFns }