var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.employees'),"description":_vm.$t('mainReport.Fromhereyoucancontrolyouremployeesreports')}})],1),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 42 || el.id == 43 || el.id == 44 || el.id == 45; }
          ) || _vm.$user.admin
        )
        &&_vm.$site.employees_allow
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.employees'),"icon":'fa-user-alt',"reports":[
          {
            name: _vm.$t('mainReport.TotalEmployeeAccount'),
            link: './employeesTotalAccounts',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 42; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.EmployeesAssets'),
            link: '/employeesBalance',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 44; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.StaffGuide'),
            link: '/employeesGuide',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 45; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e(),(
         (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 42 || el.id == 43 || el.id == 44 || el.id == 45; }
          ) || _vm.$user.admin
         ) &&
         (_vm.$site.employeesSalaries_allow ||
            _vm.$site.employeesIncentives_allow ||
            _vm.$site.employeesDeductions_allow ||
            _vm.$site.employeesWithdrawals_allow)
       )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.employeesOperations'),"icon":'fal fa-users',"reports":[
         {
           name: _vm.$t('mainReport.employeesSalaries'),
           link: './employeesOperationsSalaries',
           role:
             _vm.$user.role.available_reports.find(
               function (el) { return el.id == 42; }
             ) || _vm.$user.admin,
         },
         {
           name: _vm.$t('mainReport.employeesIncentives'),
           link: '/employeesOperationsIncentives',
           role:
             _vm.$user.role.available_reports.find(
               function (el) { return el.id == 43; }
             ) || _vm.$user.admin,
         },
         {
           name: _vm.$t('mainReport.employeesDeductions'),
           link: '/employeesOperationsDeductions',
           role:
             _vm.$user.role.available_reports.find(
               function (el) { return el.id == 44; }
             ) || _vm.$user.admin,
         },
         {
           name: _vm.$t('mainReport.employeesWithdrawals'),
           link: '/employeesOperationsWithdrawals',
           role:
             _vm.$user.role.available_reports.find(
               function (el) { return el.id == 45; }
             ) || _vm.$user.admin,
         } ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }