const routes =  [

  //safesPublic routes

    { 
      path: "safesPublic",
      name: "safesPublic",
       component: function () {
        return import( '@/accounting/safesPublic/index.vue')
      }
    },
    { 
      path: "safesPublic/create",
       name: "safesPublicCreate",
       component: function () {
        return import( '@/accounting/safesPublic/form.vue')
      }
       },
    { 
      path: "safesPublic/:id/edit",
       name: "safesPublicEdit",
       component: function () {
        return import( '@/accounting/safesPublic/form.vue')
      }
    },
    { 
      path: "safesPublic/:id",
       name: "safesPublicShow",
       component: function () {
        return import( '@/accounting/safesPublic/show.vue')
      }
    },

  //safes routes

    { 
      path: "safes", 
      name: "safes",
      component: function () {
        return import( '@/accounting/safes/index.vue')
      }
    },
    {
      path: "safes/create",
      name: "safesCreate",
      component: function () {
        return import( '@/accounting/safes/form.vue')
      }
    },
    {
      path: "safes/:id/edit",
      name: "safesEdit",
      component: function () {
        return import( '@/accounting/safes/form.vue')
      }
    },
    {
      path: "safes/explaination",
      name: "safesExplaination",
      component: function () {
        return import( '@/accounting/safes/explain.vue')
      }
    },
    {
      path: "safes/:id",
      name: "safesShow",
      component: function () {
        return import( '@/accounting/safes/show.vue')
      }
    },

  //bankAccounts routes

  {
    path: "bankAccounts",
    name: "bankAccounts",
    component: function () {
      return import( '@/accounting/bankAccounts/index.vue')
    }
  },
  {
    path: "bankAccounts/create",
    name: "bankAccountsCreate",
    component: function () {
      return import( '@/accounting/bankAccounts/form.vue')
    }
  },
  {
    path: "bankAccounts/:id/edit",
    name: "bankAccountsEdit",
    component: function () {
      return import( '@/accounting/bankAccounts/form.vue')
    }
  },
  {
    path: "bankAccounts/explaination",
    name: "bankAccountsexplaination",
    component: function () {
      return import( '@/accounting/bankAccounts/explain.vue')
    }
  },
  {
    path: "bankAccounts/:id",
    name: "bankAccountsShow",
    component: function () {
      return import( '@/accounting/bankAccounts/show.vue')
    }
  },

  // salesPayments routes

  {
    path: "salesPayments",
    name: "salesPayments",
    component: function () {
      return import( '@/accounting/salesPayments/index.vue')
    }
  },
  {
    path: "salesPayments/create",
    name: "salesPaymentsCreate",
    component: function () {
      return import( '@/accounting/salesPayments/form.vue')
    }
  },
  {
    path: "salesPayments/:id/edit",
    name: "salesPaymentsEdit",
    component: function () {
      return import( '@/accounting/salesPayments/form.vue')
    }
  },
  {
    path: "salesPayments/:id",
    name: "salesPaymentsShow",
    component: function () {
      return import( '@/accounting/salesPayments/show.vue')
    }
  },

    // purchasePayments routes

  {
    path: "purchasePayments",
    name: "purchasePayments",
    component: function () {
      return import( '@/accounting/purchasePayments/index.vue')
    }
  },
  {
    path: "purchasePayments/create",
    name: "purchasePaymentsCreate",
    component: function () {
      return import( '@/accounting/purchasePayments/form.vue')
    }
  },
  {
    path: "purchasePayments/:id/edit",
    name: "purchasePaymentsEdit",
    component: function () {
      return import( '@/accounting/purchasePayments/form.vue')
    }
  },
  {
    path: "purchasePayments/:id",
    name: "purchasePaymentsShow",
    component: function () {
      return import( '@/accounting/purchasePayments/show.vue')
    }
  },

  // expenses routes

  { 
    path: "expenses",
    name: "expenses",
     component: function () {
      return import( '@/accounting/expenses/index.vue')
    }
  },
  {
    path: "expenses/create",
    name: "expensesCreate",
    component: function () {
      return import( '@/accounting/expenses/form.vue')
    }
  },
  {
    path: "expenses/:id/edit",
    name: "expensesEdit",
    component: function () {
      return import( '@/accounting/expenses/form.vue')
    }
  },
  { path: "expenses/:id",
    name: "expensesShow",
    component: function () {
      return import( '@/accounting/expenses/show.vue')
    }
  },

  // safesDeposits routes

  {
    path: "safesDeposits",
    name: "safesDeposits",
    component: function () {
      return import( '@/accounting/safesDeposits/index.vue')
    }
  },
  {
    path: "safesDeposits/create",
    name: "safesDepositsCreate",
    component: function () {
      return import( '@/accounting/safesDeposits/form.vue')
    }
  },
  {
    path: "safesDeposits/:id/edit",
    name: "safesDepositsEdit",
    component: function () {
      return import( '@/accounting/safesDeposits/form.vue')
    }
  },
  {
    path: "safesDeposits/:id",
    name: "safesDepositsShow",
    component: function () {
      return import( '@/accounting/safesDeposits/show.vue')
    }
  },

  //safesWithdrawals routes
  {
    path: "safesWithdrawals",
    name: "safesWithdrawals",
    component: function () {
      return import( '@/accounting/safesWithdrawals/index.vue')
    }
  },
  {
    path: "safesWithdrawals/create",
    name: "safesWithdrawalsCreate",
    component: function () {
      return import( '@/accounting/safesWithdrawals/form.vue')
    }
  },
  {
    path: "safesWithdrawals/:id/edit",
    name: "safesWithdrawalsEdit",
    component: function () {
      return import( '@/accounting/safesWithdrawals/form.vue')
    }
  },
  {
    path: "safesWithdrawals/:id",
    name: "safesWithdrawalsShow",
    component: function () {
      return import( '@/accounting/safesWithdrawals/show.vue')
    }
  },

  // safesTransfers routes
      
  {
    path: "safesTransfers",
    name: "safesTransfers",
    component: function () {
      return import( '@/accounting/safesTransfers/index.vue')
    }
  },
  {
    path: "safesTransfers/create",
    name: "safesTransfersCreate",
    component: function () {
      return import( '@/accounting/safesTransfers/form.vue')
    }
  },
  {
    path: "safesTransfers/:id/edit",
    name: "safesTransfersEdit",
    component: function () {
      return import( '@/accounting/safesTransfers/form.vue')
    }
  },
  {
    path: "safesTransfers/:id",
    name: "safesTransfersShow",
    component: function () {
      return import( '@/accounting/safesTransfers/show.vue')
    }
  },




];

export default routes;
