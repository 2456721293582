var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.stores'),"description":_vm.$t('mainReport.Fromhereyoucancontrolyourstoresreports')}})],1),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 11 || el.id == 12; }
          ) || _vm.$user.admin
        ) && _vm.$site.stores_allow
        )?_c('reportsCard',{staticClass:"col-md-6 ",attrs:{"cardTitle":_vm.$t('mainReport.stores'),"icon":'fa-store-alt',"reports":[
          {
            name: _vm.$t('mainReport.InventoryOfStores'),
            link: './warehouseInventory',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 11; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.TheValueOfProducts'),
            link: '/ProductsValue',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 12; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.storeAlerts'),
            link: '/productAlerts',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 12; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 52; }
          ) || _vm.$user.admin
        ) && _vm.$site.stores_allow 
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.products'),"icon":'fa-store-alt',"reports":[
          {
            name: _vm.$t('mainReport.productAccountStatement'),
            link: './productDetailsAccounts',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 52; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.inactiveProducts'),
            link: '/inactiveProducts',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 4; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 13 || el.id == 14; }
          ) || _vm.$user.admin
        )&&
        (_vm.$site.storesDeposits_allow ||
            _vm.$site.storesWithdrawals_allow ||
            _vm.$site.storesTransfers_allow) &&
          (_vm.$user.admin ||
            _vm.$user.role.stores_deposits_show ||
            _vm.$user.role.stores_withdrawals_show ||
            _vm.$user.role.stores_transfers_show)
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.warehouseDeposit'),"icon":'far fa-store-alt',"reports":[
          {
            name: _vm.$t('mainReport.depositPerInvoice'),
            link: './storesDepositsByInvoice',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 13; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.depositProduct'),
            link: '/storesDepositsByItem',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 14; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 13 || el.id == 14; }
          ) || _vm.$user.admin
        )&&
        (_vm.$site.storesDeposits_allow ||
            _vm.$site.storesWithdrawals_allow ||
            _vm.$site.storesTransfers_allow) &&
          (_vm.$user.admin ||
            _vm.$user.role.stores_deposits_show ||
            _vm.$user.role.stores_withdrawals_show ||
            _vm.$user.role.stores_transfers_show)
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.storeWithdrawals'),"icon":'fa-file-invoice-dollar',"reports":[
          {
            name: _vm.$t('mainReport.withdrawalAccordingInvoice'),
            link: './storesWithdrawalsByBill',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 13; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.checkoutProduct'),
            link: '/storesWithdrawalsByItem',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 14; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 13 || el.id == 14; }
          ) || _vm.$user.admin
        )&&
        (_vm.$site.storesDeposits_allow ||
            _vm.$site.storesWithdrawals_allow ||
            _vm.$site.storesTransfers_allow) &&
          (_vm.$user.admin ||
            _vm.$user.role.stores_deposits_show ||
            _vm.$user.role.stores_withdrawals_show ||
            _vm.$user.role.stores_transfers_show)
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.warehouseTransfer'),"icon":'fa-file-invoice-dollar',"reports":[
          {
            name: _vm.$t('mainReport.transferinvoice'),
            link: './storesTransfersByInvoice',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 13; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.transferProduct'),
            link: '/storesTransfersByItem',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 14; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }