var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("تاريخ السداد")]),_c('VueCtkDateTimePicker',{attrs:{"label":"إختر الوقت والتاريخ","button-now-translation":"الان","format":"YYYY-MM-DD","formated":"YYYY-MM-DD","color":"#631263","button-color":"#631263","locale":"ar","range":true,"onlyDate":true,"custom-shortcuts":[
          { key: 'thisWeek', label: 'هذا الأسبوع', value: 'isoWeek' },
          { key: 'lastWeek', label: 'الاسبوع السابق', value: '-isoWeek' },
          { key: 'last7Days', label: 'أخر  7 ايام ', value: 7 },
          { key: 'last30Days', label: 'أخر 30 يوم', value: 30 },
          { key: 'thisMonth', label: 'هذا الشهر', value: 'month' },
          { key: 'lastMonth', label: 'الشهر السابق', value: '-month' },
          { key: 'thisYear', label: 'هذا العام', value: 'year' },
          { key: 'lastYear', label: 'العام السابق', value: '-year' } ]},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }