<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="'التقارير'"
          :description="'استحقاقات الأقساط'"
        />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              خيارات التقرير
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                title="العميل"
                plaseholder="اختر عميل"
                v-model="params.customer_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.customer_id"
                :values="$database.customers"
              />
              <dateInput class="col-md-3" />
              <searchInput
                class="col-md-3"
                title="المستخدم"
                plaseholder="اختر مستخدم"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="this.users"
              />
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader 
            title="استحقاقات الأقساط" 
            :details="[
              {title: 'اسم العميل', value : customerName },
              {title: 'اسم المستخدم', value : userName },
            ]"
          />
          <reportTable
            :emptyTableText="'لايوجد بيانات حاليا'"
            :emptyTableSubText="'جرب اضافة البعض واعد المحاولة'"
            :cloumns="[
              { column: 'code', link: '/salesInstallments', title: 'الكود', type:'reportMainLink', sort: true},
              { column: 'client_type', title: 'اسم العميل', type: 'clintLink'},
              { column: 'mobile', title: 'رقم الهاتف', type: 'text'},
              { column: 'address1', title: 'العنوان', type: 'text'},
              { column: 'date', title: 'التاريخ', type: 'text'},
              { column: 'total', title: 'المبلغ', type: 'text'},
              { column: 'paid', title: 'المدفوع', type: 'text'},
              { column: 'rest', title: 'الباقي', type: 'text'},
            ]"
            :footer="[
              { colspan: 5, value: 'المجموع' },
              { colspan: 1, value: total },
              { colspan: 1, value: paid },
              { colspan: 1, value: rest },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import reportHeader from "../../elements/reports/reportHeader.vue";
import reportTable from "../../elements/reports/reportTable.vue";
import indexHeader from "../../elements/index/indexHeader.vue";
import dateInput from "../../elements/reports/dateInput.vue";
import searchInput from "../../elements/reports/searchInput.vue";
import actionButtons from "../../elements/reports/actionButtons.vue";
import selectInput from "../../elements/reports/selectInput.vue";

export default {
  data() {
    return {
      path: "/reports/installments/installmentsDue",
      items: [],
      loading: 1,

      total: 0,
      paid: 0,
      rest: 0,

      params: {
        customer_id: "",
        safe_id: "",
        user_id: "",
        date: "",
      },

      excel: {
        fileName: 'installments due',
        reportData: '[]'
      },

      users: [],
      customerName: "",
      userName: "",
      safeName: "",
      pointOfSaleName:"",
      sessionNumber:"",
      searchStartDate: "",
      searchEndDate: "",
    };
  },
  methods: {
    getData() {
      this.$setParams(this.params);
      this.loading = 1;
      this.totalPages = 0;
      this.items = [];

      this.total =0
      this.paid = 0
      this.rest = 0

      axios
        .get(this.$linkGnirator(this.path), { params: this.params })
        .then((response) => {
          this.items = response.data;

          this.searchDate = this.params.date;

          this.items.forEach((item) => {
            this.total += item.total
            this.paid  += item.paid
            this.rest  += item.rest

            if (item.client_type == 0) {
              var customer = this.$database.customers.find(
                (el) => el.id == item.customer_id
              );
              if (customer) {
                item.customer = customer
                item.mobile = customer.mobile
                item.address1 = customer.address1
              }
            } else {
              var customer = this.$database.suppliers.find(
                (el) => el.id == item.supplier_id
              );
              if (customer) {
                item.supplier = customer
                item.mobile = customer.mobile
                item.address1 = customer.address1
              }
            }

            var user = this.users.find((el) => el.id == item.user_id);
            if (user) {
              item.user = user.name;
            }

            item.date  = this.$dateFormat(item.date)
            item.total = this.$moneyFormat(item.total)
            item.paid  = this.$moneyFormat(item.paid)
            item.rest  = this.$moneyFormat(item.rest)

          });

          this.total = this.$moneyFormat(this.total)
          this.paid  = this.$moneyFormat(this.paid)
          this.rest  = this.$moneyFormat(this.rest)

          this.loading = 0;
        });

      var customer = this.$database.customers.find((el) => el.id == this.params.customer_id);
      if (customer) {
        this.customerName = customer.name;
      } else{
        this.customerName =""
      }


      var user = this.users.find((el) => el.id == this.params.user_id);
      if (user) {
        this.userName = user.name;
      } else{
        this.userName =""
      }

      this.sessionNumber =  this.params.session_number
    },
    getUsers() {
      axios.get(this.$linkGnirator("/usersItems")).then((response) => {
        this.users = response.data;
      });
    },
  },
  watch: {
    items : function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data  = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map(r => [...r.querySelectorAll('th, td')].map(td => td.textContent))
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData);
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams();
    if (typeof this.params.date === 'undefined') {
      this.params.date = new Date().toISOString('en-US', { timeZone: 'Africa/Cairo' }).slice(0, 10);
    }
    this.$updateDatabase(["safes", "customers"]);
    this.getData();
    this.getUsers();
  },
  components: {
    reportHeader,
    reportTable,
    indexHeader,
    dateInput,
    searchInput,
    actionButtons,
    selectInput
  },
};
</script>
