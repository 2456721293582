<template>
    <div class="row container-fluid">
      <div class="col-12">
        <indexHeader 
          :title="$t('mainReport.Projects')"
          :description="$t('mainReport.FromhereyoucancontrolyourProjectsreports')"/>
        </div>
          <!-- تقارير المشروعات -->
          <reportsCard
            class="col-md-6"
            :cardTitle="$t('mainReport.Projects')"
            :icon="'fa-chart-line'"
            v-if="
            (
              $user.role.available_reports.find((el) => el.id == 38 || el.id == 39 || el.id == 40 || el.id == 41) || $user.admin
            ) && $site.projects_allow 
            "
            :reports="[
              {
                name: $t('mainReport.TotalProjectAccount'),
                link: './projectsTotalAccounts',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 38
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.ProjectAccountDetails'),
                link: '/projectsDetailsAccounts',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 39
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.ProjectAssets'),
                link: '/projectsBalance',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 40
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.AGeneralGlimpseOfTheProject'),
                link: '/projectsOverview',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 41
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.projectMovement'),
                link: '/projectMovement',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 41
                  ) || $user.admin,
              },
            ]"
          />
          <!-- تقارير عمليات ايداع المشروعات -->
          <reportsCard
            class="col-md-6"
            :cardTitle="$t('mainReport.ProjectFilings')"
            :icon="'fa-credit-card-front'"
            v-if="
            (
              $user.role.available_reports.find(
                (el) => el.id == 46 || el.id == 47
              ) || $user.admin
            )&& $site.projectsDeposits_allow 
            "
            :reports="[
              {
                name: $t('mainReport.InvoiceDeposits'),
                link: '/projectsDepositsByInvoice',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 46
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.ProductDeposits'),
                link: '/projectsDepositsByItem',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 47
                  ) || $user.admin,
              },
            ]"
          />
              <!-- تقارير عمليات سحب المشروعات -->
          <reportsCard
            class="col-md-6"
            :cardTitle="$t('mainReport.ProjectWithdrawals')"
            :icon="'fa-credit-card-front'"
            v-if="
            (
              $user.role.available_reports.find(
                (el) => el.id == 48 || el.id == 49
              ) || $user.admin
            )&&  $site.projectsWithdrawals_allow 
            "
            :reports="[
              {
                name: $t('mainReport.WithdrawalsByInvoice'),
                link: './projectsWithdrawalsByBill',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 48
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.ProductWithdrawals'),
                link: '/projectsWithdrawalsByItem',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 49
                  ) || $user.admin,
              },
            ]"
          />
              <!-- تقارير عمليات نقل المشروعات -->
          <reportsCard
            class="col-md-6"
            :cardTitle="$t('mainReport.ProjectTransfers')"
            :icon="'fa-exchange'"
            v-if="
            (
              $user.role.available_reports.find(
                (el) => el.id == 50 || el.id == 51
              ) || $user.admin
            )&& $site.projectsTransfers_allow
            "
            :reports="[
              {
                name: $t('mainReport.InvoiceTransfers'),
                link: '/projectsTransfersByInvoice',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 50
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.ProductTransfers'),
                link: '/projectsTransfersByItem',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 51
                  ) || $user.admin,
              },
            ]"
          />
        </div>
</template>
<script>
import reportsCard from "../elements/reports/reportsCard.vue";
import indexHeader from'../elements/index/indexHeader.vue';
export default {
    components: {
    reportsCard,
    indexHeader,
  },
}
</script>