<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="'التقارير'"
          :description="'لمحة عامة عن المشروع.'"
        />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              خيارات التقرير
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                title="المشروع"
                plaseholder="اختر مشروع"
                :show="'name'"
                :refrance="'id'"
                v-model="params.project_id"
                :value="params.project_id"
                :values="$database.projects"
              />

              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader 
            title="بيانات المشروع" 
            :details="[
              {title: 'اسم المشروع', value : projectName }
            ]"
          />
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="table-responsive">
                  <table
                    class="table table-sm table-nowrap card-table table-hover"
                  >
                    <thead>
                      <tr class="text-center">
                        <th colspan="7">بيانات المشروع</th>
                      </tr>
                      <tr class="text-center">
                        <th>اسم المشروع</th>
                        <th>تاريخ البدا</th>
                        <th>تاريخ الانتهاء</th>
                        <th>التكاليف حتي الان</th>
                        <th>المبيعات حتي الأن</th>
                        <th>الإيرادات</th>
                        <th>عدد الشركاء</th>
                      </tr>
                    </thead>
                    <tbody class="list">
                      <tr class="text-center">
                        <th>{{ project.name != "" ? project.name : "" }}</th>
                        <th>
                          {{
                            project.start_date != "" ? project.start_date : ""
                          }}
                        </th>
                        <th>
                          {{ project.end_date != "" ? project.end_date : "" }}
                        </th>
                        <th>{{ $moneyFormat(totalCost) }}</th>
                        <th>
                          {{ $moneyFormat(project.totalInvoices) }}
                        </th>
                        <th>
                          {{ $moneyFormat(totalIncome) }}
                        </th>
                        <th>{{ partners.length }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="card">
                <div class="table-responsive">
                  <table
                    class="table table-sm table-nowrap card-table table-hover"
                  >
                    <thead>
                      <tr class="text-center">
                        <th colspan="5">الشركاء بالمشروع</th>
                      </tr>
                      <tr class="text-center">
                        <th>الاسم</th>
                        <th>النسبه</th>
                        <th>نسبه المصروفات</th>
                        <th>نسبه الايرادات</th>
                        <th>الربح</th>
                      </tr>
                    </thead>
                    <tbody class="list">
                      <tr
                        class="text-center"
                        v-for="(partner, index) in partners"
                        :key="index"
                      >
                        <th>{{ partner.name }}</th>
                        <th>{{ partner.percent }} %</th>
                        <th>
                          {{ $moneyFormat(partner.cost) }}
                        </th>
                        <th>{{$moneyFormat(partner.payment)}}</th>
                        <th>{{ $moneyFormat(partner.payment - partner.cost) }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-4" v-if="!loading">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-header-title">
                    <i class="fal fa-handshake"></i>
                    نسب الشركاء
                  </h4>
                </div>
                <div class="card-body bar-chart-padding">
                  <div class="chart cercular-shart">
                    <apexchart
                      height="210"
                      :options="chartOptions"
                      :series="series"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4" v-if="!loading">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-header-title">
                    <i class="fal fa-building"></i>
                    نسبة الوحدات المباعة
                  </h4>
                </div>
                <div class="card-body bar-chart-padding">
                  <div class="chart cercular-shart">
                    <apexchart
                      height="310"
                      :options="chartOptions2"
                      :series="series3"
                    />
                    <p class="text-center">نسبة الوحدات المباعة للوحدات الكلية</p>
                  </div>
                </div>
              </div>
            </div>

             <div class="col-4" v-if="!loading">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-header-title">
                    <i class="fal fa-credit-card-front"></i>
                    التكلفة الفعلية الي المتوقعة
                  </h4>
                </div>
                <div class="card-body bar-chart-padding">
                  <div class="chart cercular-shart">
                    <apexchart
                      height="310"
                      :options="chartOptions2"
                      :series="series2"
                    />
                    <p class="text-center">التكلفة الفعلية الي المتوقعة</p>
                  </div>
                </div>
                </div>
             </div>

             <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-header-title">
                    <i class="fal fa-balance-scale"></i>
                    موازنة المشروع
                  </h4>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="table-responsive">
                      <table
                        class="table table-sm table-nowrap card-table table-hover"
                      >
                        <thead>
                          <tr class="text-center">
                            <th colspan="2">الأصول</th>
                          </tr>
                        </thead>
                        <tbody class="list">
                          <tr class="text-center">
                            <th>الخامات المرجعة</th>
                            <th>{{$moneyFormat(totalWithdrawals)}}</th>
                          </tr>
                          <tr class="text-center">
                              <th>أجمالي الدفعات</th>
                              <th>{{$moneyFormat(totalSalesPayments)}}</th>
                          </tr>
                          <tr class="text-center">
                              <th>أجمالي الشيكات</th>
                              <th>{{$moneyFormat(totalSalescheques)}}</th>
                          </tr>
                          <tr class="text-center">
                              <th>أجمالي الأقساط</th>
                              <th>{{$moneyFormat(totalSalesInstallments)}}</th>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr class="text-center">
                              <th colspan="">الأجمالي</th>
                              <th>{{$moneyFormat(totalWithdrawals + totalSalesPayments + totalSalescheques + totalSalesInstallments)}}</th>
                            </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="table-responsive">
                      <table
                        class="table table-sm table-nowrap card-table table-hover"
                      >
                        <thead>
                          <tr class="text-center">
                            <th colspan="2">الخصوم</th>
                          </tr>
                        </thead>
                        <tbody class="list">
                          <tr class="text-center">
                            <th>الخامات المودعة</th>
                            <th>{{$moneyFormat(totalDeposits)}}</th>
                          </tr>
                          <tr class="text-center" v-for="(expince, index) in expenses" :key="index">
                              <th>{{expince.name}}</th>
                              <th>{{$moneyFormat(expince.cost)}}</th>
                          </tr>
                          <tr class="text-center">
                            <th>ناتج العملية</th>
                            <th>{{$moneyFormat((totalWithdrawals + totalSalesPayments + totalSalescheques + totalSalesInstallments) - (totalDeposits + totalExpenses))}}</th>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr class="text-center">
                              <th colspan="">الأجمالي</th>
                              <th>{{$moneyFormat(totalWithdrawals + totalSalesPayments + totalSalescheques + totalSalesInstallments)}}</th>
                            </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import axios from "axios";

import reportHeader from "../../elements/reports/reportHeader.vue";
import indexHeader from "../../elements/index/indexHeader.vue";
import searchInput from "../../elements/reports/searchInput.vue";
import actionButtons from "../../elements/reports/actionButtons.vue";
export default {
  data() {
    return {
      path: "/reports/projects/overview",
      logo: localStorage.getItem("logo"),
      project: [],

      partners: [],
      expenses: [],

      totalDeposits: 0,
      totalWithdrawals: 0,
      totalExpenses: 0,
      totalInvoices: 0,
      totalCost: 0,
      totalSalesPayments: 0,
      totalSalescheques: 0,
      totalSalesInstallments: 0,
      totalIncome: 0,
      invoicesCount: 0,
      loading: 0,

      params: {
        project_id: "",
      },
      excel: {
        fileName: 'project overview',
        reportData: '[]'
      },

      projectName: "",
      chartOptions: {
        chart: {
          type: "pie",
          fontFamily: "Tajawal",
        },
        colors: ["#011627", "#2ec4b6", "#c55778", "#e71d36", "#01162775"],
        labels: [],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -20,
            },
          },
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "right",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 10,
          offsetY: 40,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 2,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
      },
      series: [],
      series2: [],
      series3: [],
      chartOptions2: {
            chart: {
              type: 'radialBar',
              offsetY: -20,
              sparkline: {
                enabled: true
              }
            },
            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                  background: "#e7e7e7",
                  strokeWidth: '97%',
                  margin: 5, // margin is in pixels
                },
                dataLabels: {
                  name: {
                    show: false
                  },
                  value: {
                    offsetY: -2,
                    fontSize: '22px'
                  }
                }
              }
            },
            grid: {
              padding: {
                top: -10
              }
            },
            fill: {
              colors: ['#011627']
            },
            labels: ['نسبةالوحدات المباعة للوحدات الكلية'],
          },
    };
  },
  methods: {
    getData() {
      this.$setParams(this.params);
      this.loading = 1;

      this.totalDeposits = 0;

      this.totalWithdrawals = 0;
      this.totalExpenses = this.totalCost = 0;

      this.totalInvoices = 0;

      this.totalSalesPayments = 0;
      this.totalSalescheques = 0;
      this.totalSalesInstallments = 0;

      this.totalIncome = 0;

      this.invoicesCount = 0;

      this.partners = [];
      this.expenses = [];

      axios
        .get(this.$linkGnirator(this.path), { params: this.params })
        .then((response) => {
          if (response.data != "") {
            this.project = response.data;
            this.project.start_date = new Date(this.project.start_date * 1000)
              .toISOString('en-US', { timeZone: 'Africa/Cairo' })
              .slice(0, 10);
            this.project.end_date = new Date(this.project.end_date * 1000)
              .toISOString('en-US', { timeZone: 'Africa/Cairo' })
              .slice(0, 10);

            this.totalDeposits = this.project.totalDeposits;
            this.totalWithdrawals = this.project.totalWithdrawals;
            this.totalExpenses = this.project.totalExpenses;
            this.totalInvoices = this.project.totalInvoices;

            this.totalCost = this.totalDeposits + this.totalExpenses;

            this.totalSalesPayments = this.project.totalSalesPayments;
            this.totalSalescheques = this.project.totalSalescheques;
            this.totalSalesInstallments = this.project.totalSalesInstallments;

            this.totalIncome =
              this.totalSalesPayments +
              this.totalSalescheques +
              this.totalSalesInstallments;


            this.invoicesCount = this.project.invoicesCount;

            var totalSettlementCount = 0;
            this.project.partnersSettlementItems.forEach((item) => {
              var partner = this.partners.find(
                (el) => el.id == item.partner_id
              );
              if (partner) {
                partner.cost = partner.cost + item.cost;
              } else {
                this.partners.push({
                  id: item.partner_id,
                  cost: item.cost,
                  payment: 0
                });
              }
              totalSettlementCount += item.cost;
            });

            this.project.partnersProfitItems.forEach((item) => {
              var partner = this.partners.find(
                (el) => el.id == item.partner_id
              );
              if (partner) {
                partner.payment = partner.payment + item.cost;
              } else {
                this.partners.push({
                  id: item.partner_id,
                  cost: 0,
                  payment: item.cost,
                });
              }
            });

            this.partners.forEach((item) => {
              var partner = this.$database.partners.find(
                (el) => el.id == item.id
              );
              item.name = partner ? partner.name : "--";
              item.percent = ((item.cost / totalSettlementCount) * 100).toFixed(2);
            });
            
            this.project.expenses.forEach((item) => {
              var expense = this.expenses.find((el) => el.id == item.expense_section_id);
              if (expense) {
                expense.cost = expense.cost + item.cost;
              } else {
                var name = ""
                if (item.expense_section_id != 0) {
                  var expenseSection = this.$database.expenseSections.find(el => el.id == item.expense_section_id)
                  name = expenseSection.name
                } else {
                  name = 'مصروفات اخري'
                }
                this.expenses.push({
                  id: item.expense_section_id,
                  name: name,
                  cost: item.cost,
                });
              }
            });
            

            this.chartOptions.labels = this.partners.map((el) => el.name);
            this.series = this.partners.map((el) => el.cost);

            this.series2 = [this.project.expected_cost != 0 ? (this.totalCost / this.project.expected_cost * 100).toFixed(2) : 0]
          
            this.series3 = [this.project.number_of_units != 0 ? (this.invoicesCount / this.project.number_of_units * 100).toFixed(2) : 0]

            this.projectName = this.project.name;
            this.loading = 0;
          }
        });
    }
  },
  watch: {
    items : function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data  = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map(r => [...r.querySelectorAll('th, td')].map(td => td.textContent))
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData);
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams();
    if (this.params.project_id) {
      this.getData();
    }
  },
  components: {
    reportHeader,
    indexHeader,
    searchInput,
    actionButtons,
  },
};
</script>
