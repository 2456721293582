var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'archway',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat
          ? _vm.$t('capitals.show.active')
          : _vm.$t('capitals.show.inactive'),"subData":[
        {
          title: _vm.$t('capitals.show.currentBalance'),
          value: this.$moneyFormat(_vm.item.cost),
        } ],"buttons":[
        {
          title: _vm.$t('capitals.show.edit'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$user.admin || _vm.$user.role.customers_edit,
        } ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('capitals.show.OriginInformation'),"cloumns":{
        firstCloum: [
          { icon: 'signature', title: _vm.$t('capitals.show.OriginalName'), value: _vm.item.name },
          {
            icon: 'minus-hexagon',
            title: _vm.$t('capitals.show.DepreciationRate'),
            value: _vm.item.depreciation_rate,
          },
          { icon: 'calendar', title: _vm.$t('capitals.show.PurchaseDate'), value: _vm.item.date } ],
        scondCloum: [
          {
            icon: 'dollar-sign',
            title: _vm.$t('capitals.show.Value'),
            value: this.$moneyFormat(_vm.item.cost),
          },
          {
            icon: 'toggle-on',
            title: _vm.$t('capitals.show.Status'),
            value: _vm.item.stat != 0 ? _vm.$t('capitals.show.effective') : _vm.$t('capitals.show.Ineffective'),
          },
          {
            icon: 'calendar',
            title: _vm.$t('capitals.show.DateOfSale'),
            value: _vm.item.expiry_date,
          } ],
      },"paragraphs":[
        { title: _vm.$t('capitals.show.DescriptionOfTheOriginal'), value: _vm.item.descripton },
        { title: _vm.$t('capitals.show.OtherDetailedData'), value: _vm.item.details },
        { title: _vm.$t('capitals.show.Reviews'), value: _vm.item.notes } ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }