
const routes =  [

      {
        path: "pointOfSales",
        name: "pointOfSales",
        component: function () {
          return import( '@/accounting/pointOfSales/index.vue')
        }
      },
      {
        path: "pointOfSales/create",
        name: "pointOfSalesCreate",
        component: function () {
          return import( '@/accounting/pointOfSales/form.vue')
        }
      },
      {
        path: "pointOfSales/:id/edit",
        name: "pointOfSalesEdit",
        component: function () {
          return import( '@/accounting/pointOfSales/form.vue')
        }
      },
      {
        path: "pointOfSales/:id",
        name: "pointOfSalesShow",
        component: function () {
          return import( '@/accounting/pointOfSales/show.vue')
        }
      },
      {
        path: "pointOfSales/:id/invoices",
        name: "pointOfSalesInvoicesSingle",
        component: function () {
          return import( '@/accounting/pointOfSales/invoices.vue')
        }
      },
      {
        path: "pointOfSales/:id/shippingOperations",
        name: "pointOfSalesShippingOperations",
        component: function () {
          return import( '@/accounting/pointOfSales/shippingOperations.vue')
        }
      },
      {
        path: "pointOfSales/:id/expenses",
        name: "pointOfSalesExpenses",
        component: function () {
          return import( '@/accounting/pointOfSales/expenses.vue')
        }
      }
];

export default routes;