const routes =  [
    { 
      path: "salesCheques", 
      name: "salesCheques",
      component: function () {
        return import( '@/accounting/salesCheques/index.vue')
      }
    },
    {
      path: "salesCheques/create",
      name: "saleschequesCreate",
      component: function () {
        return import( '@/accounting/salesCheques/form.vue')
      }
    },
    {
      path: "salesCheques/:id/edit",
      name: "saleschequesEdit",
      component: function () {
        return import( '@/accounting/salesCheques/form.vue')
      }
    },
    {
      path: "salesCheques/:id",
      name: "saleschequesShow",
      component: function () {
        return import( '@/accounting/salesCheques/show.vue')
      }
    },

    //purchaseCheques
    {
      path: "purchaseCheques",
      name: "purchaseCheques",
      component: function () {
        return import( '@/accounting/purchaseCheques/index.vue')
      }
    },
    {
      path: "purchaseCheques/create",
      name: "purchaseChequesCreate",
      component: function () {
        return import( '@/accounting/purchaseCheques/form.vue')
      }
    },
    {
      path: "purchaseCheques/:id/edit",
      name: "purchaseChequesEdit",
      component: function () {
        return import( '@/accounting/purchaseCheques/form.vue')
      }
    },
    {
      path: "purchaseCheques/:id",
      name: "purchaseChequesShow",
      component: function () {
        return import( '@/accounting/purchaseCheques/show.vue')
      }
    },
];

export default routes;
