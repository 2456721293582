<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('profitsAndLosses.Accounting')" :description="$t('profitsAndLosses.ProfitsAndLosses')" />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{$t('profitsAndLosses.ReportOptions')}}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <dateFilter class="col-md-3" />
              <searchInput
                class="col-md-3"
                :title="$t('profitsAndLosses.User')"
                :plaseholder="$t('profitsAndLosses.ChooseAUser')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="this.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('profitsAndLosses.PointOfSale')"
                :plaseholder="$t('profitsAndLosses.ChooseASellingPoint')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.point_of_sale_id"
                :value="params.point_of_sale_id"
                :values="$database.pointOfSales"
              />

              <div class="col-md-3">
                <div class="form-group">
                  <label>{{$t('profitsAndLosses.SessionNo')}}</label>
                  <input
                    type="text"
                    v-model="params.session_number"
                    class="form-control"
                    :placeholder="$t('profitsAndLosses.EnterTheSessionNumber')"
                  />
                </div>
              </div>
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <div class="card">
            <div class="card-header col-12 p-4">
              <h4 class="card-header-title col-4">
                <i class="fal fa-chart-bar"></i>
                {{$t('profitsAndLosses.ProfitsAndLosses')}}
              </h4>
              <div style="text-align: center" class="col-4">
                <p class="mt-1" v-if="userName">
                  <strong>{{$t('profitsAndLosses.Username')}} </strong>{{ userName }}
                </p>

                <p class="mt-1" v-if="pointOfSaleName">
                  <strong> {{$t('profitsAndLosses.PointOfSale')}} </strong>{{ pointOfSaleName }}
                </p>

                <p class="mt-1" v-if="sessionNumber">
                  <strong> {{$t('profitsAndLosses.SessionNo')}} </strong>{{ sessionNumber }}
                </p>
              </div>
              <p class="col-4 text-left">
                {{$t('profitsAndLosses.FromTheDate')}} {{ searchStartDate ? searchStartDate : "--" }} {{$t('profitsAndLosses.into')}}
                {{ searchEndDate ? searchEndDate : "--" }}
              </p>
            </div>
            <div class="table-responsive">
              <table class="table table-sm table-nowrap card-table table-hover">
                <tbody class="list" v-if="loading != 1">
                  <tr class="clum-head">
                    <td colspan="100" class="text-center">
                      <strong>{{$t('profitsAndLosses.Revenue')}}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('profitsAndLosses.Sales')}} {{$t('profitsAndLosses.invoices')}}</strong></td>
                    <td>{{ totalInvoices }} {{ $option.currency }}</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('profitsAndLosses.bills')}}</strong></td>
                    <td>
                      {{ totalPurchasesReturns }} {{ $option.currency }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('profitsAndLosses.TotalRevenue')}}</strong></td>
                    <td>
                      {{ totalInvoices + totalPurchasesReturns }}
                      {{ $option.currency }}
                    </td>
                  </tr>
                  <tr class="clum-head">
                    <td colspan="100" class="text-center">
                      <strong>{{$t('profitsAndLosses.expenses')}}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('profitsAndLosses.bills')}}</strong></td>
                    <td>{{ totalBills }} {{ $option.currency }}</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('profitsAndLosses.expenses')}}</strong></td>
                    <td>{{ totalExpenses }} {{ $option.currency }}</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('profitsAndLosses.invoices')}}</strong></td>
                    <td>
                      {{ totalSalesReturns }} {{ $option.currency }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('profitsAndLosses.TotalExpenses')}}</strong></td>
                    <td>
                      {{ totalBills + totalExpenses + totalSalesReturns }}
                      {{ $option.currency }}
                    </td>
                  </tr>
                  <tr class="clum-head">
                    <td>{{$t('profitsAndLosses.Profit')}}</td>
                    <td>
                      {{
                        totalInvoices +
                        (totalPurchasesReturns - 0) -
                        totalBills -
                        totalExpenses -
                        totalSalesReturns
                      }}
                      {{ $option.currency }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import indexHeader from "../elements/index/indexHeader.vue";
import dateFilter from "../elements/reports/dateFilter.vue";
import searchInput from "../elements/reports/searchInput.vue";
import actionButtons from "../elements/reports/actionButtons.vue";
export default {
  data() {
    return {
      path: "/accounting/profitsAndLosses",

      invoices: [],
      salesReturns: [],
      bills: [],
      purchasesReturns: [],
      expenses: [],

      totalExpenses: 0,
      totalBills: 0,
      totalInvoices: 0,
      totalSalesReturns: 0,
      totalPurchasesReturns: 0,
      
      loading: 0,

      params: {
        store_id: "",
        user_id: "",
        point_of_sale_id: "",
        session_number: "",
        dateFilter: 2,
        startDate: "",
        endDate: "",
      },

      users: [],

      searchStartDate: "",
      searchEndDate: "",

      storeName: "",
      userName: "",
      pointOfSaleName: "",
      sessionNumber: "",
    };
  },
  methods: {
    getData() {
      this.loading = 1;
      this.invoices = [];
      this.salesReturns = [];
      this.bills = [];
      this.purchasesReturns = [];
      this.expenses = [];

      this.totalInvoices = 0;
      this.totalSalesReturns = 0;
      this.totalBills = 0;
      this.totalPurchasesReturns = 0;
      this.totalExpenses = 0;

      axios
        .get(this.$linkGnirator(this.path), { params: this.params })
        .then((response) => {
          this.invoices = response.data.invoices;
          this.salesReturns = response.data.salesReturns;
          this.bills = response.data.bills;
          this.purchasesReturns = response.data.purchasesReturns;
          this.expenses = response.data.expenses;

          this.invoices.forEach((item) => {
            this.totalInvoices += item.total;
          });

          this.salesReturns.forEach((item) => {
            this.totalSalesReturns += item.total;
          });

          this.bills.forEach((item) => {
            this.totalBills += item.total;
          });

          this.purchasesReturns.forEach((item) => {
            this.totalPurchasesReturns += item.total;
          });

          this.expenses.forEach((item) => {
            this.totalExpenses += item.cost;
          });


          var store = this.$database.stores.find(
            (el) => el.id == this.params.store_id
          );
          if (store) {
            this.storeName = store.name;
          } else {
            this.storeName = "";
          }

          var user = this.users.find((el) => el.id == this.params.user_id);
          if (user) {
            this.userName = user.name;
          } else {
            this.userName = "";
          }

          this.sessionNumber = this.params.session_number;

          var pointOfSale = this.$database.pointOfSales.find(
            (el) => el.id == this.params.point_of_sale_id
          );
          if (pointOfSale) {
            this.pointOfSaleName = pointOfSale.name;
          } else {
            this.pointOfSaleName = "";
          }

          this.searchStartDate = this.params.startDate;
          this.searchEndDate = this.params.endDate;

          this.loading = 0;

          
        });
    },
    print() {
      var text = document.getElementById("reportShow").innerHTML;
      var myframe = document.getElementById("printIframe");
      myframe.contentDocument.body.innerHTML =
        '<div class="text-center"> <img src="' +
        this.$linkGnirator(
          "/companiesUploads/" +
            localStorage.getItem("subdomain") +
            "/logos/" +
            this.$option.image
        ) +
        '" class="img-fluid mb-4" style="max-width: 4.5rem;" v-if="$option.image != null"><h2 class="mb-2">' +
        this.$option.name +
        "</h2></div>" +
        text;
      setTimeout(function () {
        myframe.focus();
        myframe.contentWindow.print();
      }, 100);
    },
    getUsers() {
      axios.get(this.$linkGnirator("/usersItems")).then((response) => {
        this.users = response.data;
      });
    },
  },
  mounted() {
    this.params = this.$getParams();
    if (typeof this.params.startDate === "undefined") {
      this.params.startDate = new Date().toISOString('en-US', { timeZone: 'Africa/Cairo' }).slice(0, 10);
    }
    this.getData();
    this.getUsers();
  },
  components: {
    indexHeader,
    dateFilter,
    searchInput,
    actionButtons,
  },
};
</script>
