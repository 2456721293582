var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.Checks'),"description":_vm.$t('mainReport.FromhereyoucancontrolyourChecksreports')}})],1),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 27 || el.id == 28; }
          ) || _vm.$user.admin
        )&& 
        (_vm.$site.salesCheques_allow ||
        _vm.$site.purchaseCheques_allow) &&
      (_vm.$user.admin ||
        _vm.$user.role.sales_cheques_show ||
        _vm.$user.role.purchase_cheques_show)
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.Checks'),"icon":'fa-money-check-alt',"reports":[
          {
            name: _vm.$t('mainReport.RegisterSalesCheques'),
            link: './chequesHistory',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 27; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.CheckInRecord'),
            link: '/purchaseChequesHistory',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 28; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }