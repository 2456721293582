const routes =  [

  //partners routes

    { 
      path: "partners", 
      name: "partners",
      component: function () {
        return import( '../accounting/partners/index.vue')
      }
    },
    {
      path: "partners/create",
      name: "partnersCreate",
      component: function () {
        return import( '../accounting/partners/form.vue')
      }
    },
    {
      path: "partners/:id/edit",
      name: "partnersEdit",
      component: function () {
        return import( '../accounting/partners/form.vue')
      }
    },
    {
      path: "partners/:id",
      name: "partnersShow",
      component: function () {
        return import( '../accounting/partners/show.vue')
      }
    },

  //partnersDeposits routes

  {
      path: "partnersDeposits",
      name: "partnersDeposits",
      component: function () {
        return import( '../accounting/partnersDeposits/index.vue')
      }
    },
    {
      path: "partnersDeposits/create",
      name: "partnersDepositsCreate",
      component: function () {
        return import( '../accounting/partnersDeposits/form.vue')
      }
    },
    {
      path: "partnersDeposits/:id/edit",
      name: "partnersDepositsEdit",
      component: function () {
        return import( '../accounting/partnersDeposits/form.vue')
      }
    },
    {
      path: "partnersDeposits/:id",
      name: "partnersDepositsShow",
      component: function () {
        return import( '../accounting/partnersDeposits/show.vue')
      }
    },

    //partnersWithdrawals routes
    {
      path: "partnersWithdrawals",
      name: "partnersWithdrawals",
      component: function () {
        return import( '../accounting/partnersWithdrawals/index.vue')
      }
    },
    {
      path: "partnersWithdrawals/create",
      name: "partnersWithdrawalsCreate",
      component: function () {
        return import( '../accounting/partnersWithdrawals/form.vue')
      }
    },
    {
      path: "partnersWithdrawals/:id/edit",
      name: "partnersWithdrawalsEdit",
      component: function () {
        return import( '../accounting/partnersWithdrawals/form.vue')
      }
    },
    {
      path: "partnersWithdrawals/:id",
      name: "partnersWithdrawalsShow",
      component: function () {
        return import( '../accounting/partnersWithdrawals/show.vue')
      }
    },

    //partnersSettlements routes

    {
      path: "partnersSettlements",
      name: "partnersSettlements",
      component: function () {
        return import( '../accounting/partnersSettlements/index.vue')
      }
    },
    {
      path: "partnersSettlements/create",
      name: "partnersSettlementsCreate",
      component: function () {
        return import( '../accounting/partnersSettlements/form.vue')
      }
    },
    {
      path: "partnersSettlements/:id/edit",
      name: "partnersSettlementsEdit",
      component: function () {
        return import( '../accounting/partnersSettlements/form.vue')
      }
    },
    {
      path: "partnersSettlements/:id",
      name: "partnersSettlementsShow",
      component: function () {
        return import( '../accounting/partnersSettlements/show.vue')
      }
    },

    //partnersProfits routes

    {
      path: "partnersProfits",
      name: "partnersProfits",
      component: function () {
        return import( '../accounting/partnersProfits/index.vue')
      }
    },
    {
      path: "partnersProfits/create",
      name: "partnersProfitsCreate",
      component: function () {
        return import( '../accounting/partnersProfits/form.vue')
      }
    },
    {
      path: "partnersProfits/:id/edit",
      name: "partnersProfitsEdit",
      component: function () {
        return import( '../accounting/partnersProfits/form.vue')
      }
    },
    {
      path: "partnersProfits/:id",
      name: "partnersProfitsShow",
      component: function () {
        return import( '../accounting/partnersProfits/show.vue')
      }
    },
];

export default routes;
