const routes =  [
  {
    path: "accountsTransfers",
    name: "accountsTransfers",
    component: function () {
      return import( '@/accounting/accountsTransfers/index.vue')
    }
  },
  {
    path: "accountsTransfers/create",
    name: "accountsTransfersCreate",
    component: function () {
      return import( '@/accounting/accountsTransfers/form.vue')
    }
  },
  {
    path: "accountsTransfers/:id/edit",
    name: "accountsTransfersEdit",
    component: function () {
      return import( '@/accounting/accountsTransfers/form.vue')
    }
  },
  {
    path: "accountsTransfers/:id",
    name: "accountsTransfersShow",
    component: function () {
      return import( '@/accounting/accountsTransfers/show.vue')
    }
  },
];

export default routes;
