const routes =  [

      // endorsements routes
      {
        path: "endorsements",
        name: "endorsements",
        component: function () {
          return import( '@/accounting/endorsements/index.vue')
        }
      },
      {
        path: "endorsements/create",
        name: "endorsementsCreate",
        component: function () {
          return import( '@/accounting/endorsements/form.vue')
        }
      },
      {
        path: "endorsements/:id/edit",
        name: "endorsementsEdit",
        component: function () {
          return import( '@/accounting/endorsements/form.vue')
        }
      },
      {
        path: "endorsements/:id",
        name: "endorsementsShow",
        component: function () {
          return import( '@/accounting/endorsements/show.vue')
        }
      },

      // deposits routes

      { path: "deposits",
        name: "deposits",
        component: function () {
          return import( '@/accounting/deposits/index.vue')
        }
      },
      {
        path: "deposits/create",
        name: "depositsCreate",
        component: function () {
          return import( '@/accounting/deposits/form.vue')
        }
      },
      {
        path: "deposits/:id/edit",
        name: "depositsEdit",
        component: function () {
          return import( '@/accounting/deposits/form.vue')
        }
      },
      { path: "deposits/:id",
        name: "depositsShow",
        component: function () {
          return import( '@/accounting/deposits/show.vue')
        }
      },

      // goldInvoice routes
      {
        path: "goldInvoice",
        name: "goldInvoice",
        component: function () {
          return import( '@/accounting/freePrint/goldInvoice.vue')
        }
      },     

      // diamondInvoice routes
      {
        path: "diamondInvoice",
        name: "diamondInvoice",
        component: function () {
          return import( '@/accounting/freePrint/diamondInvoice.vue')
        }
      },

];

export default routes;
