var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'سجل اقساط البيع'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"العميل","plaseholder":"اختر عميل","show":'name',"refrance":'id',"value":_vm.params.customer_id,"values":_vm.$database.customers},model:{value:(_vm.params.customer_id),callback:function ($$v) {_vm.$set(_vm.params, "customer_id", $$v)},expression:"params.customer_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المستخدم","plaseholder":"اختر مستخدم","show":'name',"refrance":'id',"value":_vm.params.user_id,"values":this.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المشروع","plaseholder":"اختر مشروع","show":'name',"refrance":'id',"value":_vm.params.project_id,"values":_vm.$database.projects},model:{value:(_vm.params.project_id),callback:function ($$v) {_vm.$set(_vm.params, "project_id", $$v)},expression:"params.project_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"سجل اقساط البيع","details":[
            {title: 'اسم العميل', value : _vm.customerName },
            {title: 'اسم المستخدم', value : _vm.userName } ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            { column: 'code', link: '/salesInstallments', title: 'الكود', type:'reportMainLink', sort: true},
            { column: 'date', title: 'التاريخ', type: 'text', sort: true },
            { column: 'client_type', title: 'اسم العميل', type: 'clintLink'},
            { column: 'user', title: 'المستخدم', type: 'text', sort: true },
            { column: 'cost', title: 'المبلغ', type: 'text', sort: true},
            { column: 'benefit', title: 'الفائدة', type: 'text', sort: true},
            { column: 'total', title: 'الأجمالي', type: 'text', sort: true},
            { column: 'paid', title: 'المدفوع', type: 'text', sort: true},
            { column: 'rest', title: 'الباقي', type: 'text', sort: true} ],"footer":[
            { colspan: 4, value: 'المجموع' },
            { colspan: 1, value:_vm.cost },
            { colspan: 1, value:_vm.benefit },
            { colspan: 1, value:_vm.total },
            { colspan: 1, value:_vm.paid },
            { colspan: 1, value:_vm.rest }
          ]}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])}]

export { render, staticRenderFns }