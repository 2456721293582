var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('invoiceStatus.invoiceStatus'),"description":_vm.$t('invoiceStatus.FromHereYouCanControlTheStatusesOfYourGroups')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('invoiceStatus.SearchForACase'),"emptyTableText":_vm.$t('invoiceStatus.ThereAreNoCases'),"localData":true,"buttonRole":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                   {column: 'name',    title:_vm.$t('invoiceStatus.StatusName') ,type: 'text' , sort: true },
                   {column: 'stat',    title:_vm.$t('invoiceStatus.theStatus')      ,type: 'stat'     , sort: true, hideMobile: true },
                   {column: 'options', title:_vm.$t('invoiceStatus.Settings'), type: 'options', options: [
                       {name: 'edit',   role: true},
                       {name: 'delete', role: true} ]}
                 ],"deleteText":{
                  attention: _vm.$t('allerts.Attention'),
                  areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                  close: _vm.$t('allerts.close'),
                  confirm: _vm.$t('allerts.confirm'),
                  loading: _vm.$t('allerts.loading'),
                 }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }