const routes =  [

       //manufacturingModels routes

    { 
      path: "manufacturingModels", 
      name: "manufacturingModels",
      component: function () {
        return import( '@/accounting/manufacturingModels/index.vue')
      }
    },
    {
      path: "manufacturingModels/create",
      name: "manufacturingmodelsCreate",
      component: function () {
        return import( '@/accounting/manufacturingModels/form.vue')
      }
    },
    {
      path: "manufacturingModels/:id/edit",
      name: "manufacturingmodelsEdit",
      component: function () {
        return import( '@/accounting/manufacturingModels/form.vue')
      }
    },
    {
      path: "manufacturingModels/:id",
      name: "manufacturingmodelsShow",
      component: function () {
        return import( '@/accounting/manufacturingModels/show.vue')
      }
    },

     //manufacturingOperations routes
     {
      path: "manufacturingOperations",
      name: "manufacturingOperations",
      component: function () {
        return import( '@/accounting/manufacturingOperations/index.vue')
      }
    },
    {
      path: "manufacturingOperations/create",
      name: "manufacturingOperationsCreate",
      component: function () {
        return import( '@/accounting/manufacturingOperations/form.vue')
      }
    },
    {
      path: "manufacturingOperations/:id/edit",
      name: "manufacturingOperationsEdit",
      component: function () {
        return import( '@/accounting/manufacturingOperations/form.vue')
      }
    },
    {
      path: "manufacturingOperations/:id",
      name: "manufacturingOperationsShow",
      component: function () {
        return import( '@/accounting/manufacturingOperations/show.vue')
      }
    },
    //manufacturingDeposits routes
    {
      path: "manufacturingDeposits",
      name: "manufacturingDeposits",
      component: function () {
        return import( '@/accounting/manufacturingDeposits/index.vue')
      }
    },
    {
      path: "manufacturingDeposits/create",
      name: "manufacturingDepositsCreate",
      component: function () {
        return import( '@/accounting/manufacturingDeposits/form.vue')
      }
    },
    {
      path: "manufacturingDeposits/:id/edit",
      name: "manufacturingDepositsEdit",
      component: function () {
        return import( '@/accounting/manufacturingDeposits/form.vue')
      }
    },
    {
      path: "manufacturingDeposits/:id",
      name: "manufacturingDepositsShow",
      component: function () {
        return import( '@/accounting/manufacturingDeposits/show.vue')
      }
    },

    //manufacturingWithdrawals routes
    {
      path: "manufacturingWithdrawals",
      name: "manufacturingWithdrawalsIndex",
      component: function () {
        return import( '@/accounting/manufacturingWithdrawals/index.vue')
      }
    },
    {
      path: "manufacturingWithdrawals/create",
      name: "manufacturingWithdrawalsCreate",
      component: function () {
        return import( '@/accounting/manufacturingWithdrawals/form.vue')
      }
    },
    {
      path: "manufacturingWithdrawals/:id/edit",
      name: "manufacturingWithdrawalsEdit",
      component: function () {
        return import( '@/accounting/manufacturingWithdrawals/form.vue')
      }
    },
    {
      path: "manufacturingWithdrawals/:id",
      name: "manufacturingWithdrawalsShow",
      component: function () {
        return import( '@/accounting/manufacturingWithdrawals/show.vue')
      }
    },


    //manufacturingTransfers routes
    {
      path: "manufacturingTransfers",
      name: "manufacturingTransfersIndex",
      component: function () {
        return import( '@/accounting/manufacturingTransfers/index.vue')
      }
    },
    {
      path: "manufacturingTransfers/create",
      name: "manufacturingTransfersCreate",
      component: function () {
        return import( '@/accounting/manufacturingTransfers/form.vue')
      }
    },
    {
      path: "manufacturingTransfers/:id/edit",
      name: "manufacturingTransfersEdit",
      component: function () {
        return import( '@/accounting/manufacturingTransfers/form.vue')
      }
    },
    {
      path: "manufacturingTransfers/:id",
      name: "manufacturingTransferShow",
      component: function () {
        return import( '@/accounting/manufacturingTransfers/show.vue')
      }
    },
];

export default routes;
