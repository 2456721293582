const routes =  [
    { 
      path: "subscriptions", 
      name: "subscriptions",
      component: function () {
        return import( '../accounting/subscriptions/index.vue')
      }
    },
    {
      path: "subscriptions/create",
      name: "subscriptionsCreate",
      component: function () {
        return import( '../accounting/subscriptions/form.vue')
      }
    },
    {
      path: "subscriptions/:id/edit",
      name: "subscriptionsEdit",
      component: function () {
        return import( '../accounting/subscriptions/form.vue')
      }
    },
    {
      path: "subscriptions/:id",
      name: "subscriptionsShow",
      component: function () {
        return import( '../accounting/subscriptions/show.vue')
      }
    },

    //subscriptions operations
      { 
        path: "subscriptionsOperations", 
        name: "subscriptionsOperations", 
        component: function () {
          return import( '../accounting/subscriptionsOperations/index.vue')
        }
      },
      {
        path: "subscriptionsOperations/create",
        name: "subscriptionsOperationsCreate",
        component: function () {
          return import( '../accounting/subscriptionsOperations/form.vue')
        }
      },
      {
        path: "subscriptionsOperations/:id/edit",
        name: "subscriptionsOperationsEdit",
        component: function () {
          return import( '../accounting/subscriptionsOperations/form.vue')
        }
      },
      {
        path: "subscriptionsOperations/:id",
        name: "subscriptionsOperationsShow",
        component: function () {
          return import( '../accounting/subscriptionsOperations/show.vue')
        }
      },
];

export default routes;
