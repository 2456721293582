<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('reports.accounts.reports')"
          :description="$t('reports.cheques.registerSalesChecks')"
        />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{$t('reports.accounts.reportOptions')}}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <searchInput
                class="col-md-3"
                :title="$t('reports.cheques.client')"
                :plaseholder="$t('reports.cheques.chooseCustomer')"
                v-model="params.customer_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.customer_id"
                :values="$database.customers"
              />
              <dateFilter class="col-md-3" />
              <endDateFilter class="col-md-3" />

              <searchInput
                class="col-md-3"
                :title="$t('reports.overview.theUser')"
                :plaseholder="$t('reports.overview.chooseUser')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="this.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('reports.cheques.project')"
                :plaseholder="$t('reports.cheques.chooseProject')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.project_id"
                :value="params.project_id"
                :values="$database.projects"
              />
              <searchInput
                class="col-md-3"
                :title="$t('reports.overview.treasury')"
                :plaseholder="$t('reports.overview.ChooseSafe')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.safe_id"
                :value="params.safe_id"
                :values="$database.safes"
              />

              <div class="col-md-3">
                <div class="form-group">
                  <label>{{$t('reports.overview.statue')}}</label>
                  <select class="form-control" v-model="params.stat">
                    <option value="">{{$t('reports.overview.chooseStatue')}}</option>
                    <option value="0">{{$t('reports.overview.waiting')}}</option>
                    <option value="1">{{$t('reports.overview.paid')}}</option>
                  </select>
                </div>
              </div>

              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>

        <div id="reportShow">
          <reportHeader 
            :title="$t('reports.overview.recordSalesCheques')" 
            :details="[
              {title: $t('reports.overview.customerName'), value : customerName },
              {title: $t('reports.overview.userName'), value : userName },
              {title: $t('reports.overview.treasury'), value : safeName },
              {title: $t('reports.overview.statue'), value : stateName }
            ]"
          />
          <reportTable
            :emptyTableText="$t('reports.accounts.thereNoDataTheMoment')"
            :emptyTableSubText="$t('reports.accounts.tryAddingSomeAndTryAgain')"
            :cloumns="[
              {
                column: 'code',
                link: '/salesCheques',
                title: $t('reports.overview.code'),
                type: 'reportMainLink',
                sort: true,
              },
              {
                column: 'date',
                title: $t('reports.overview.paymentDate'),
                type: 'text',
                sort: true,
              },
              { column: 'customer', title: $t('reports.overview.customerName'), type: 'text', sort: true },
              { column: 'user', title: $t('reports.overview.userName'), type: 'text', sort: true },
              { column: 'safe', title: $t('reports.overview.treasury'), type: 'text', sort: true },
              { column: 'paymentMethod', title: $t('reports.overview.paymentMethod'), type: 'text', sort: true,},
              { column: 'stat', title: $t('reports.overview.statue'), type: 'text', sort: true },
              { column: 'cost', title: $t('reports.overview.theAmount'), type: 'text', sort: true },
            ]"
            :footer="[
              { colspan: 7, value: $t('reports.overview.total') },
              { colspan: 1, value: cost },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import reportHeader from "../../elements/reports/reportHeader.vue";
import reportTable from "../../elements/reports/reportTable.vue";
import indexHeader from "../../elements/index/indexHeader.vue";
import dateFilter from "../../elements/reports/dateFilter.vue";
import endDateFilter from "../../elements/reports/endDateFilter.vue";
import searchInput from "../../elements/reports/searchInput.vue";
import actionButtons from "../../elements/reports/actionButtons.vue";

export default {
  data() {
    return {
      path: "/reports/cheques/chequesHistory",
      items: [],
      loading: 1,

      cost: 0,

      customerName: "",
      userName: "",
      safeName: "",
      stateName: "",

      params: {
        customer_id: "",
        user_id: "",
        safe_id: "",
        stat: "",
        dateFilter: 2,
        startDate: "",
        endDate: "",
        paymentStartDate: "",
        paymentEndDate: "",
      },

      excel: {
        fileName: 'cheques history',
        reportData: '[]'
      },

      users: [],

      searchStartDate: "",
      searchEndDate: "",
      searchPaymentStartDate: "",
      searchPaymentEndDate: "",
    };
  },
  methods: {
    getData() {
      this.$setParams(this.params);
      0;
      this.loading = 1;
      this.totalPages = 0;
      this.items = [];
      axios
        .get(this.$linkGnirator(this.path), { params: this.params })
        .then((response) => {
          this.items = response.data;

          this.searchStartDate = this.params.startDate;
          this.searchEndDate = this.params.endDate;

          this.searchPaymentStartDate = this.params.paymentStartDate;
          this.searchPaymentEndDate = this.params.paymentEndDate;

          this.cost = 0;

          this.items.forEach((item) => {
            this.cost += item.cost;
            item.cost = this.$moneyFormat(item.cost);

            item.date = this.$dateFormat(item.date);

            item.client_type = 0;
            if (item.client_type == 0) {
              var customer = this.$database.customers.find(
                (el) => el.id == item.customer_id
              );
              if (customer) {
                item.customer = customer.name;
              }
            } else {
              var customer = this.$database.suppliers.find(
                (el) => el.id == item.supplier_id
              );
              if (customer) {
                item.customer = customer.name;
              }
            }
            var user = this.users.find((el) => el.id == item.user_id);
            if (user) {
              item.user = user.name;
            }

            var safe = this.$database.safes.find(
              (el) => el.id == item.safe_id
            );
            if (safe) {
              item.safe = safe.name;
            }

            var paymentMethod = this.$database.paymentMethods.find(
              (el) => el.id == item.payment_method_id
            );
            if (paymentMethod) {
              item.paymentMethod = paymentMethod.name;
            }

            item.stat = item.stat == 0 ? "قيد الأنتظار" : "مدفوعة";
          });

          this.cost = this.$moneyFormat(this.cost);

          this.loading = 0;
        });
      var customer = this.$database.customers.find(
        (el) => el.id == this.params.customer_id
      );
      if (customer) {
        this.customerName = customer.name;
      } else {
        this.customerName = "";
      }

      var user = this.users.find((el) => el.id == this.params.user_id);
      if (user) {
        this.userName = user.name;
      } else {
        this.userName = "";
      }

      var safe = this.$database.safes.find(
        (el) => el.id == this.params.safe_id
      );
      if (safe) {
        this.safeName = safe.name;
      } else {
        this.safeName = "";
      }

      this.params.stat == 0
        ? (this.stateName = " قيد الأنتظار")
        : (this.stateName = " مدفوعة");
    },
    getUsers() {
      axios.get(this.$linkGnirator("/usersItems")).then((response) => {
        this.users = response.data;
      });
    }
  },
  watch: {
    items : function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data  = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map(r => [...r.querySelectorAll('th, td')].map(td => td.textContent))
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData);
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams();
    if (typeof this.params.startDate === "undefined") {
      this.params.startDate = new Date()
        .toISOString("en-US", { timeZone: "Africa/Cairo" })
        .slice(0, 10);
    }
    this.$updateDatabase(["safes", "customers"]);
    this.getData();
    this.getUsers();
  },
  components: {
    reportHeader,
    reportTable,
    indexHeader,
    dateFilter,
    searchInput,
    actionButtons,
    endDateFilter,
  },
};
</script>
