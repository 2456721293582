var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.installments'),"description":_vm.$t('mainReport.Fromhereyoucancontrolyourinstallmentsreports')}})],1),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 29 || el.id == 30; }
          ) || _vm.$user.admin
        )&& 
        (_vm.$site.salesInstallments_allow ||
            _vm.$site.purchaseInstallments_allow) &&
          (_vm.$user.admin ||
            _vm.$user.role.sales_installments_show ||
            _vm.$user.role.purchase_installments_show)
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.installments'),"icon":'fa-wallet',"reports":[
          {
            name: _vm.$t('mainReport.salesInstallmentsHistory'),
            link: './installmentsHistory',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 29; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.salesInstallmentsDue'),
            link: './installmentsDue',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 29; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.installmentsPaymentsHistory'),
            link: './installmentsPaymentsHistory',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 29; }
              ) || _vm.$user.admin,
          }
        ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }