const routes =  [
    { 
      path: "employees", 
      name: "employees",
      component: function () {
        return import( '../accounting/employees/index.vue')
      }
    },
    {
      path: "employees/create",
      name: "employeesCreate",
      component: function () {
        return import( '../accounting/employees/form.vue')
      }
    },
    {
      path: "employees/:id/edit",
      name: "employeesEdit",
      component: function () {
        return import( '../accounting/employees/form.vue')
      }
    },
    {
      path: "employees/:id",
      name: "employeesShow",
      component: function () {
        return import( '../accounting/employees/show.vue')
      }
    },

    //employees salaries
    { path: "employeesSalaries",
        name: "employeesSalaries",
        component: function () {
          return import( '../accounting/employeesSalaries/index.vue')
        }
      },
      {
        path: "employeesSalaries/create",
        name: "employeesSalariesCreate",
        component: function () {
          return import( '../accounting/employeesSalaries/form.vue')
        }
      },
      {
        path: "employeesSalaries/:id/edit",
        name: "employeesSalariesEdit",
        component: function () {
          return import( '../accounting/employeesSalaries/form.vue')
        }
      },
      { path: "employeesSalaries/:id",
        name: "employeesSalariesShow",
        component: function () {
          return import( '../accounting/employeesSalaries/show.vue')
        } 
      },

      //employees incentives
      { path: "employeesIncentives",
        name: "employeesIncentives",
        component: function () {
          return import( '../accounting/employeesIncentives/index.vue')
        }
      },
      {
        path: "employeesIncentives/create",
        name: "employeesIncentivesCreate",
        component: function () {
          return import( '../accounting/employeesIncentives/form.vue')
        }
      },
      {
        path: "employeesIncentives/:id/edit",
        name: "employeesIncentivesEdit",
        component: function () {
          return import( '../accounting/employeesIncentives/form.vue')
        }
      },
      { path: "employeesIncentives/:id",
        name: "employeesIncentivesShow",
        component: function () {
          return import( '../accounting/employeesIncentives/show.vue')
        }
     },

     //employees deductions
     { path: "employeesDeductions",
        name: "employeesDeductions",
        component: function () {
          return import( '../accounting/employeesDeductions/index.vue')
        }
      },
      {
        path: "employeesDeductions/create",
        name: "employeesDeductionsCreate",
        component: function () {
          return import( '../accounting/employeesDeductions/form.vue')
        }
      },
      {
        path: "employeesDeductions/:id/edit",
        name: "employeesDeductionsEdit",
        component: function () {
          return import( '../accounting/employeesDeductions/form.vue')
        }
      },
      { path: "employeesDeductions/:id",
        name: "employeesDeductionsShow",
        component: function () {
          return import( '../accounting/employeesDeductions/show.vue')
        } 
      },

      //employees withdrawals
      { path: "employeesWithdrawals",
        name: "employeesWithdrawals",
        component: function () {
          return import( '../accounting/employeesWithdrawals/index.vue')
        } 
      },
      {
        path: "employeesWithdrawals/create",
        name: "employeesWithdrawalsCreate",
        component: function () {
          return import( '../accounting/employeesWithdrawals/form.vue')
        }
      },
      {
        path: "employeesWithdrawals/:id/edit",
        name: "employeesWithdrawalsEdit",
        component: function () {
          return import( '../accounting/employeesWithdrawals/form.vue')
        }
      },

      { path: "employeesWithdrawals/:id",
        name: "employeesWithdrawalsShow",
        component: function () {
          return import( '../accounting/employeesWithdrawals/show.vue')
        } 
      },


];

export default routes;
