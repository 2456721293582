const routes =  [
    { 
      path: "projects", 
      name: "projects",
      component: function () {
        return import( '@/accounting/projects/index.vue')
      }
    },
    {
      path: "projects/create",
      name: "projectsCreate",
      component: function () {
        return import( '@/accounting/projects/form.vue')
      }
    },
    {
      path: "projects/:id/edit",
      name: "projectsEdit",
      component: function () {
        return import( '@/accounting/projects/form.vue')
      }
    },
    {
      path: "projects/:id",
      name: "projectsShow",
      component: function () {
        return import( '@/accounting/projects/show.vue')
      }
    },

    //projectsDeposits routes
    {
      path: "projectsDeposits",
      name: "projectsDeposits",
      component: function () {
        return import( '@/accounting/projectsDeposits/index.vue')
      }
    },
    {
      path: "projectsDeposits/create",
      name: "projectsDepositsCreate",
      component: function () {
        return import( '@/accounting/projectsDeposits/form.vue')
      }
    },
    {
      path: "projectsDeposits/:id/edit",
      name: "projectsDepositsEdit",
      component: function () {
        return import( '@/accounting/projectsDeposits/form.vue')
      }
    },
    {
      path: "projectsDeposits/:id",
      name: "projectsDepositsShow",
      component: function () {
        return import( '@/accounting/projectsDeposits/show.vue')
      }
    },

    //projectsWithdrawals routes
    {
      path: "projectsWithdrawals",
      name: "projectsWithdrawalsIndex",
      component: function () {
        return import( '@/accounting/projectsWithdrawals/index.vue')
      }
    },
    {
      path: "projectsWithdrawals/create",
      name: "projectsWithdrawalsCreate",
      component: function () {
        return import( '@/accounting/projectsWithdrawals/form.vue')
      }
    },
    {
      path: "projectsWithdrawals/:id/edit",
      name: "projectsWithdrawalsEdit",
      component: function () {
        return import( '@/accounting/projectsWithdrawals/form.vue')
      }
    },
    {
      path: "projectsWithdrawals/:id",
      name: "projectsWithdrawalsShow",
      component: function () {
        return import( '@/accounting/projectsWithdrawals/show.vue')
      }
    },

    //projectsTransfers routes
    {
      path: "projectsTransfers",
      name: "projectsTransfers",
      component: function () {
        return import( '@/accounting/projectsTransfers/index.vue')
      }
    },
    {
      path: "projectsTransfers/create",
      name: "projectsTransfersCreate",
      component: function () {
        return import( '@/accounting/projectsTransfers/form.vue')
      }
    },
    {
      path: "projectsTransfers/:id/edit",
      name: "projectsTransfersEdit",
      component: function () {
        return import( '@/accounting/projectsTransfers/form.vue')
      }
    },
    {
      path: "projectsTransfers/:id",
      name: "projectsTransfersShow",
      component: function () {
        return import( '@/accounting/projectsTransfers/show.vue')
      }
    },
];

export default routes;
