import Vue from 'vue'

Vue.prototype.$moneyFormat = function (int) {
if (isNaN(int) ||  isNaN(parseFloat(int))) {
    int = 0
}
let str = int.toLocaleString(undefined, {style: 'currency', currency: 'EGP', minimumFractionDigits: 2, maximumFractionDigits: 2});
return str;
};

Vue.prototype.$linkGnirator = function (link, file, params) {  
    var pramsText = ""
    if (typeof params === 'object') {
    var str = [];
    for (var p in params) {
        if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
        }
    }
    pramsText = "&" + str.join("&");
    }
    
    return file ? localStorage.getItem('site') + link + localStorage.getItem('auth') + pramsText : localStorage.getItem('site') + '/api' + link + localStorage.getItem('auth') + pramsText;
};

Vue.prototype.$setParams = function (params) {
var url = new URL(window.location.href);
var search_params = url.searchParams;
Object.keys(params).forEach(function (key) {
    search_params.set(key, params[key]);
});
url.search = search_params.toString();
location.replace(window.location.href.split("?")[0] + url.search);
};

Vue.prototype.$isFloat = function (value) {
    if (
      typeof value === 'number' &&
      !Number.isNaN(value) &&
      !Number.isInteger(value)
    ) {
      return true;
    }
  
    return false;
  }

Vue.prototype.$dynamicSort = function(params) {
    var sortOrder = 1
    if (params.orderType === 'asc') {
        sortOrder = -1
    }
    return function(a, b) {
        var result = a[params.orderBy] < b[params.orderBy] ? -1 : a[params.orderBy] > b[params.orderBy] ? 1 : 0
        return result * sortOrder
    }
}

Vue.prototype.$getParams = function () {
    var searchParams = new URLSearchParams(window.location.href.split("?")[1]).entries();
    let result = {}
    for(let entry of searchParams) {
        result[entry[0]] = entry[1];
    }
    return result;
};

Vue.prototype.$fixDate = function (str) {
let arabicNumbers = [/٠/g,/١/g,/٢/g,/٣/g,/٤/g,/٥/g,/٦/g,/٧/g,/٨/g,/٩/g];
if (typeof str === "string") {
    for (var i = 0; i < 10; i++) {
    str = str.replace(arabicNumbers[i], i);
    str = str.replace("ص", "am").replace("م", "pm");
    }
}
return str;
};

Vue.prototype.$moneyFormat = function (int) {
if (isNaN(int) ||  isNaN(parseFloat(int))) {
    int = 0
}
if (Vue.prototype.$option.show_currency) {
    var str = int.toLocaleString(undefined, {style: 'currency', currency: Vue.prototype.$option.currency, minimumFractionDigits: 2, maximumFractionDigits: 2});
} else {
    var str = int.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

return str;
};

Vue.prototype.$dateFormat = function (int, inputDate) {
if (isNaN(int) ||  isNaN(parseFloat(int))) {
    return '--';
}
    var date = new Date((int) * 1000);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; 
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var outDate = ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
    var reversedDate = date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
    var outTime = hours + ":" + minutes + " " + ampm
    let str = Vue.prototype.$option.show_time ? outDate + ", " + outTime : outDate;
    if(inputDate == 'date') {
        return outDate;
    }
    if (inputDate == 'time') {
        return outTime;
    }
    if (inputDate) {
        str =  reversedDate + " " + outTime;
    }
    return str;
};

Vue.prototype.$nowDate = function (after) {
    var today = new Date();
    var hours = today.getHours();
    var minutes = today.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    if (after) {
        today.setDate(today.getDate() + 5);
    } 
    var outDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);
    return outDate +" " + hours +":" + minutes +" " + ampm;
};

Vue.directive('click-outside', {
bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
    if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
    }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
},
unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
},
});

Vue.prototype.$tafqeet = function (numIn=0, code = Vue.prototype.$option.currency, op={}) {
    let iso= Vue.prototype.$tafqeetISOList[code];if(!iso)throw new Error("Currency code not in the list!");
    let arr=(numIn+="").split((0.1).toLocaleString().substring(1,2)),
    out=nToW(arr[0],iso.uGender=="female",op,[iso.uSingle,iso.uDouble,iso.uPlural]),
    frc=arr[1]?(arr[1]+"000").substring(0,iso.fraction):0;
    if (frc !=0){out+="، و"+(op.format=="short"?frc+"/1"+"0".repeat(+iso.fraction)+" "+iso.uSingle:
    nToW(frc,iso.sGender=="female",op,[iso.sSingle,iso.sDouble,iso.sPlural]));}
    return out;
    
    function nToW(numIn=0,fm,{comma,legal}={},names){
    if(numIn==0)return"صفر "+iso.uSingle;
    let tS=[,"ألف","مليون","مليار","ترليون","كوادرليون","كوينتليون","سكستليون"],tM=["","واحد","اثنان","ثلاثة","أربعة","خمسة","ستة","سبعة","ثمانية","تسعة","عشرة"],tF=["","واحدة","اثنتان","ثلاث","أربع","خمس","ست","سبع","ثمان","تسع","عشر"],
    num=(numIn+=""),tU=[...tM],t11=[...tM],out="",n99,SpWa=" و",miah="مائة",
    last=~~(((numIn="0".repeat(numIn.length*2%3)+numIn).replace(/0+$/g,"").length+2)/3)-1;
    t11[0]="عشر";t11[1]="أحد";t11[2]="اثنا";
    numIn.match(/.{3}/g).forEach((n,i)=>+n&&(out+=do999(numIn.length/3-i-1,n,i==last),i!==last&&(out+=(comma=='on'?"،":"")+SpWa)));
    let sub=" "+names[0],n=+(num+"").slice(-2);if(n>10)sub=" "+tanween(names[0]);else if(n>2)sub=" "+names[2];
    else if(n>0)sub=names[n-1]+" "+(fm?tF[n]:tM[n]);
    return out+sub;
    
    function tanween(n,a=n.split` `,L=a.length-1){
    const strTF=(str,l=str.slice(-1),o=str+"ًا")=>{return"ا"==l?o=str.slice(0,-1)+"ًا":"ة"==l&&(o=str+"ً"),o;};
    for(let i=0;i<=L;i++)if(!i||i==L)a[i]=strTF(a[i]);return a.join` `;}
    
    function do999(sPos,num,last){
    let scale=tS[sPos],n100=~~(num/100),nU=(n99=num%100)%10,n10=~~(n99/10),w100="",w99="",e8=(nU==8&&fm&&!scale)?"ي":"";
    if (fm&&!scale){[tU,t11,t11[0],t11[1],t11[2]]=[[...tF],[...tF],"عشرة","إحدى","اثنتا"];if(n99>20)tU[1]="إحدى";}
    if(n100){if(n100>2)w100=tF[n100]+miah;else if(n100==1)w100=miah;else w100=miah.slice(0,-1)+(!n99||legal=="on"?"تا":"تان");}
    if(n99>19)w99=tU[nU]+(nU?SpWa:"")+(n10==2?"عشر":tF[n10])+"ون";
    else if(n99>10)w99=t11[nU]+e8+" "+t11[0];else if(n99>2)w99=tU[n99]+e8;let nW=w100+(n100 && n99?SpWa:"")+w99;
    if(!scale)return nW;if(!n99)return nW+" "+scale;if(n99>2)return nW+" "+(n99>10?scale+(last?"":"ًا")
    :(sPos<3?[,"آلاف","ملايين"][sPos]:tS[sPos]+"ات"));nW=(n100?w100+((legal=="on"&&n99<3)?" "+scale:"")+SpWa:"")+scale;
    return(n99==1)?nW:nW+(last?"ا":"ان");}}};

    Vue.prototype.$tafqeetISOList = {
    AED:{uSingle :"درهم إماراتي",uDouble:"درهمان إماراتيان",uPlural:"دراهم إماراتية",uGender:"male",
     sSingle :"فلس",sDouble:"فلسان",sPlural:"فلوس",sGender:"male",fraction:2},
    
    BHD:{uSingle :"دينار بحريني",uDouble:"ديناران بحرينيان",uPlural:"دنانير بحرينية",uGender:"male",
     sSingle :"فلس",sDouble:"فلسان",sPlural:"فلوس",sGender:"male",fraction:3},
    
    EGP:{uSingle :"جنيه مصري",uDouble:"جنيهان مصريان",uPlural:"جنيهات مصرية",uGender:"male",
     sSingle :"قرش",sDouble:"قرشان",sPlural:"قروش",sGender:"male",fraction:2},
    
    EUR:{uSingle :"يورو",uDouble:"يوروان",uPlural:"يوروات",uGender:"male",
     sSingle:"سنت",sDouble:"سنتان",sPlural:"سنتات",sGender:"male",fraction:2},
    
    GBP:{uSingle :"جنيه إسترليني",uDouble:"جنيهان إسترلينيان",uPlural:"جنيهات إسترلينية",uGender:"male",
     sSingle :"بنس",sDouble:"بنسان",sPlural:"بنسات",sGender:"male",fraction:2},
    
    INR:{uSingle :"روبية هندية",uDouble:"روبيتان هنديتان",uPlural:"روبيات هندية",uGender:"female",
     sSingle :"بيسة",sDouble:"بيستان",sPlural:"بيسات",sGender:"female",fraction:2},
    
    IQD:{uSingle :"دينار عراقي",uDouble:"ديناران عراقيان",uPlural:"دنانير عراقية",uGender:"male",
     sSingle :"فلس",sDouble:"فلسان",sPlural:"فلوس",sGender:"male",fraction:2},
    
    JOD:{uSingle :"دينار أردني",uDouble:"ديناران أردنيان",uPlural:"دنانير أردنية",uGender:"male",
     sSingle :"فلس",sDouble:"فلسان",sPlural:"فلوس",sGender:"male",fraction:3},
    
    KWD:{uSingle :"دينار كويتي",uDouble:"ديناران كويتيان",uPlural:"دنانير كويتية",uGender:"male",
     sSingle :"فلس",sDouble:"فلسان",sPlural:"فلوس",sGender:"male",fraction:3},
    
    LBP:{uSingle :"ليرة لبنانية",uDouble:"ليرتان لبنانيتان",uPlural :"ليرات لبنانية",uGender:"female",
     sSingle :"قرش",sDouble:"قرشان",sPlural:"قروش",sGender:"male",fraction:2},
    
    LYD:{uSingle :"دينار ليبي",uDouble:"ديناران ليبيان",uPlural:"دنانير ليبية",uGender:"male",
     sSingle:"درهم",sDouble:"درهمان",sPlural: "دراهم",sGender:"male",fraction:3},
    
    MAD:{uSingle :"درهم مغربي",uDouble:"درهمان مغربيان",uPlural:"دراهم مغربية",uGender:"male",
     sSingle :"سنتيم",sDouble:"سنتيمان",sPlural:"سنتيمات",sGender:"male",fraction:2},
    
    OMR:{uSingle :"ريال عماني",uDouble:"ريالان عمانيان",uPlural:"ريالات عمانية",uGender:"male",
     sSingle :"بيسة",sDouble:"بيستان",sPlural:"بيسات",sGender:"female",fraction:3},
    
    QAR:{uSingle:"ريال قطري",uDouble:"ريالان قطريان",uPlural:"ريالات قطرية",uGender:"male",
     sSingle:"درهم",sDouble:"درهمان",sPlural: "دراهم",sGender:"male",fraction:2},
    
    SAR:{uSingle:"ريال سعودي",uDouble:"ريالان سعوديان",uPlural:"ريالات سعودية",uGender:"male",
     sSingle:"هللة",sDouble:"هللتان",sPlural: "هللات",sGender:"female",fraction:2},
    
    SDG:{uSingle :"جنيه سوداني",uDouble:"جنيهان سودانيان",uPlural:"جنيهات سودانية",uGender:"male",
     sSingle :"قرش",sDouble:"قرشان",sPlural:"قروش",sGender:"male",fraction:2},
    
    SOS:{uSingle :"شلن صومالي",uDouble:"شلنان صوماليان",uPlural:"شلنات صومالية",uGender:"male",
     sSingle:"سنت",sDouble:"سنتان",sPlural:"سنتات",sGender:"male",fraction:2},
    
    SSP:{uSingle :"جنيه جنوب سوداني",uDouble:"جنيهان جنوب سودانيان",uPlural:"جنيهات جنوب سودانية",uGender:"male",
     sSingle :"قرش",sDouble:"قرشان",sPlural:"قروش",sGender:"male",fraction:2},
    
    SYP:{uSingle :"ليرة سورية",uDouble:"ليرتان سوريتان",uPlural :"ليرات سورية",uGender:"female",
     sSingle :"قرش",sDouble:"قرشان",sPlural:"قروش",sGender:"male",fraction:2},
    
    TND:{uSingle :"دينار تونسي",uDouble:"ديناران تونسيان",uPlural:"دنانير تونسية",uGender:"male",
     sSingle :"مليم",sDouble:"مليمان",sPlural:"ملاليم",sGender:"male",fraction:3},
    
    USD:{uSingle:"دولار أمريكي",uDouble:"دولاران أمريكيان",uPlural:"دولارات أمريكية",uGender:"male",
     sSingle:"سنت",sDouble:"سنتان",sPlural:"سنتات",sGender:"male",fraction:2},
    
    YER:{uSingle:"ريال يمني",uDouble:"ريالان يمنيان",uPlural:"ريالات يمنية",uGender:"male",
     sSingle:"فلس",sDouble:"فلسان",sPlural: "فلوس",sGender:"male",fraction:2},
    
   
    }; 