<template>
    <div class="row container-fluid">
      <div class="col-12">
        <indexHeader 
          :title="$t('mainReport.installments')"
          :description="$t('mainReport.Fromhereyoucancontrolyourinstallmentsreports')"/>
        </div>
          <!-- تقارير الأقساط -->
          <reportsCard
            class="col-md-6"
            :cardTitle="$t('mainReport.installments')"
            :icon="'fa-wallet'"
            v-if="
            (
              $user.role.available_reports.find(
                (el) => el.id == 29 || el.id == 30
              ) || $user.admin
            )&& 
            ($site.salesInstallments_allow ||
                $site.purchaseInstallments_allow) &&
              ($user.admin ||
                $user.role.sales_installments_show ||
                $user.role.purchase_installments_show)
            "
            :reports="[
              {
                name: $t('mainReport.salesInstallmentsHistory'),
                link: './installmentsHistory',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 29
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.salesInstallmentsDue'),
                link: './installmentsDue',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 29
                  ) || $user.admin,
              },
              {
                name: $t('mainReport.installmentsPaymentsHistory'),
                link: './installmentsPaymentsHistory',
                role:
                  $user.role.available_reports.find(
                    (el) => el.id == 29
                  ) || $user.admin,
              }
            ]"
          />
        </div>
</template>
<script>
import reportsCard from "../elements/reports/reportsCard.vue";
import indexHeader from'../elements/index/indexHeader.vue';
export default {
    components: {
    reportsCard,
    indexHeader,
  },
}
</script>