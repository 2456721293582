var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'ارصدة الخزائن.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"الخزينة","plaseholder":"اختر خزينة","show":'name',"refrance":'id',"value":_vm.params.safe_id,"values":_vm.$database.safes},model:{value:(_vm.params.safe_id),callback:function ($$v) {_vm.$set(_vm.params, "safe_id", $$v)},expression:"params.safe_id"}}),_c('selectInput',{staticClass:"col-md-3",attrs:{"title":"الحالة","plaseholder":"اختر حالة","values":[
                { name: 'مدين', value: 0 },
                { name: 'دائن', value: 1 },
                { name: 'موقوف', value: 2 }
              ]},model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"ارصدة الخزائن","details":[
            {title: 'اسم الخزينة', value : _vm.safeName },
            {title: 'الحالة', value: _vm.statusName}
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            {column: 'name',  link: '/safes',   title:'الخزينه' ,type: 'reportMainLink' , sort: true },
            { column: 'balance', title: 'الرصيد', type: 'text', sort: 'true'} ],"footer":[
            { colspan: 1, value: 'المجموع' },
            { colspan: 1, value: _vm.balance} ]}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])}]

export { render, staticRenderFns }