import saless from "./sales";
import purchase from "./purchase";
import safes from "./safes";
import stores from "./stores";
import employees from './employees';
import partners from './partners';
import accounts from './accounts';
import projects from './projects';
import subscriptions from './subscriptions';
import cheques from './cheques';
import discounts from './discounts';
import installments from './installments';
import manufacturing from './manufacturing';
import shippings from './shipping';
import accountsTransfer from './accountsTransfer';
import freeprinted from './freeprinted';
import pointOfSales from "./pointOfSales";

// GeneralViews
import NotFound from "../accounting/404.vue";
import login from "../accounting/login.vue";
// Admin pages
import accounting from "../accounting/main.vue";
//backup page
import backup from "../accounting/backup.vue";

import container from "../accounting/container.vue";


//capitals pages
import capitalsIndex from "../accounting/capitals/index.vue";
import capitalsForm from "../accounting/capitals/form.vue";
import capitalsShow from "../accounting/capitals/show.vue";




//units pages
import unitsIndex from "../accounting/units/index.vue";
import unitsForm from "../accounting/units/form.vue";

//unitsCoefficients pages
import unitsCoefficientsIndex from "../accounting/unitsCoefficients/index.vue";
import unitsCoefficientsForm from "../accounting/unitsCoefficients/form.vue";

//sections pages
import sectionsIndex from "../accounting/sections/index.vue";
import sectionsForm from "../accounting/sections/form.vue";

//sectionsAccounts pages
import sectionsAccountsIndex from "../accounting/sectionsAccounts/index.vue";
import sectionsAccountsForm from "../accounting/sectionsAccounts/form.vue";

//Signatures pages
import signaturesIndex from "../accounting/signatures/index.vue";
import signaturesForm from "../accounting/signatures/form.vue";


//priceLists pages
import priceListsIndex from "../accounting/priceLists/index.vue";
import priceListsForm from "../accounting/priceLists/form.vue";

//details pages
import detailsIndex from "../accounting/details/index.vue";
import detailsForm from "../accounting/details/form.vue";

//productOptions pages
import productOptionsIndex from "../accounting/productOptions/index.vue";
import productOptionsFrom from "../accounting/productOptions/form.vue";

//taxes pages
import taxesIndex from "../accounting/taxes/index.vue";
import taxesForm from "../accounting/taxes/form.vue";

//discounts pages
import discountsIndex from "../accounting/discounts/index.vue";
import discountsForm from "../accounting/discounts/form.vue";

//users pages
import usersIndex from "../accounting/users/index.vue";
import usersForm from "../accounting/users/form.vue";

//roles pages
import rolesIndex from "../accounting/roles/index.vue";
import rolesForm from "../accounting/roles/form.vue";

//companies pages
import companiesIndex from "../accounting/companies/index.vue";
import companiesForm from "../accounting/companies/form.vue";

//invoiceGroups pages
import invoiceGroupsIndex from "../accounting/invoiceGroups/index.vue";
import invoiceGroupsForm from "../accounting/invoiceGroups/form.vue";

//invoiceStatus pages
import invoiceStatusIndex from "../accounting/invoiceStatus/index.vue";
import invoiceStatusForm from "../accounting/invoiceStatus/form.vue";

//=========================================================================//



//paymentMethods pages
import paymentMethodsIndex from "../accounting/paymentMethods/index.vue";
import paymentMethodsForm from "../accounting/paymentMethods/form.vue";

//paymentFees pages
import paymentFeesIndex from "../accounting/paymentFees/index.vue";
import paymentFeesForm from "../accounting/paymentFees/form.vue";

//expenseSections pages
import expenseSectionsIndex from "../accounting/expenseSections/index.vue";
import expenseSectionsForm from "../accounting/expenseSections/form.vue";
//import expenseSectionsShow   from "../accounting/expenseSections/show.vue";

//PaymentsSections pages
import PaymentsSectionsIndex from "../accounting/PaymentsSections/index.vue";
import PaymentsSectionsForm from "../accounting/PaymentsSections/form.vue";




//pointOfSales pages
import pointOfSalesIndex from "../accounting/pointOfSales/index.vue";
import pointOfSalesForm from "../accounting/pointOfSales/form.vue";
import pointOfSalesShow from "../accounting/pointOfSales/show.vue";


import marketersIndex from "../accounting/marketers/index.vue";
import marketersForm from "../accounting/marketers/form.vue";

import applicationsIndex from "../accounting/applications/index.vue";
import applicationsWhatsapp from "../accounting/applications/whatsapp.vue";
import applicationsmessages from "../accounting/applications/messages.vue";
// -- REPORTS -- //

import mainReport from "../accounting/reports/main.vue";
import totalReport from "../accounting/reports/totalReport.vue";

import overview from "../accounting/reports/overview.vue";
import sales from "../accounting/reports/sales.vue";
import purchases from "../accounting/reports/purchases.vue";
import lockers from "../accounting/reports/lockers.vue";
import stocks from "../accounting/reports/stocks.vue";
import employee from "../accounting/reports/employee.vue";
import partner from "../accounting/reports/partner.vue";
import accountOperation from "../accounting/reports/accountOperation.vue";
import project from "../accounting/reports/project.vue";
import checks from "../accounting/reports/checks.vue";
import discount from "../accounting/reports/discount.vue";
import installment from "../accounting/reports/installment.vue";
import pointOfSale from "../accounting/reports/pointOfSale.vue";
import shipping from "../accounting/reports/shipping.vue";
import pointOfSalesOverview from "../accounting/reports/pointOfSalesOverview.vue";

//customers reports
import customersTotalAccounts from "../accounting/reports/customers/totalAccounts.vue";
import customersDetailsAccounts from "../accounting/reports/customers/detailsAccounts.vue";
import customersBalance from "../accounting/reports/customers/balance.vue";
import customersGuide from "../accounting/reports/customers/guide.vue";
import customersInactive from "../accounting/reports/customers/inactive.vue";
import customersActive from "../accounting/reports/customers/active.vue";
import customerTraffic from "../accounting/reports/customers/customerTraffic.vue";
import salesCollection from "../accounting/reports/customers/salesCollection.vue";

//suppliers reports
import suppliersTotalAccounts from "../accounting/reports/suppliers/totalAccounts.vue";
import suppliersDetailsAccounts from "../accounting/reports/suppliers/detailsAccounts.vue";
import suppliersBalance from "../accounting/reports/suppliers/balance.vue";
import suppliersGuide from "../accounting/reports/suppliers/guide.vue";
import suppliersInactive from "../accounting/reports/suppliers/inactive.vue";
import supplierMovement from "../accounting/reports/suppliers/supplierMovement.vue";
import purchasingCollecting from "../accounting/reports/suppliers/purchasingCollecting.vue";



//partners reports
import partnersTotalAccounts from "../accounting/reports/partners/totalAccounts.vue";
import partnersDetailsAccounts from "../accounting/reports/partners/detailsAccounts.vue";
import partnersBalance from "../accounting/reports/partners/balance.vue";
import partnersGuide from "../accounting/reports/partners/guide.vue";
import partnerMovement from "../accounting/reports/partners/partnerMovement.vue";

//accounts reports
import totalAccounts from "../accounting/reports/accounts/totalAccounts.vue";
import accountDetails from "../accounting/reports/accounts/detailsAccounts.vue";
import accountBalances from "../accounting/reports/accounts/balance.vue";
import accountsGuide from "../accounting/reports/accounts/guide.vue";
import movementAccounts from "../accounting/reports/accounts/movementAccounts.vue";

//employees reports
import employeesTotalAccounts from "../accounting/reports/employees/totalAccounts.vue";
import employeesBalance from "../accounting/reports/employees/balance.vue";
import employeesGuide from "../accounting/reports/employees/guide.vue";

//employeesOperations reports
import employeesOperationsDeductions from "../accounting/reports/employeesOperations/employeesDeductions.vue";
import employeesOperationsIncentives from "../accounting/reports/employeesOperations/employeesIncentives.vue";
import employeesOperationsSalaries from "../accounting/reports/employeesOperations/employeesSalaries.vue";
import employeesOperationsWithdrawals from "../accounting/reports/employeesOperations/employeesWithdrawals.vue";

//safes reports
import safesAccounts from "../accounting/reports/safes/accounts.vue";
import safersBalance from "../accounting/reports/safes/balance.vue";
import safesMove from "../accounting/reports/safes/safesMove.vue";

//stores reports
import warehouseInventory from "../accounting/reports/stores/warehouseInventory.vue";
import ProductsValue from "../accounting/reports/stores/ProductsValue.vue";
import productAlerts from "../accounting/reports/stores/productAlerts.vue";

//sales reports
import salesByInvoice from "../accounting/reports/sales/invoice.vue";
import salesByItem from "../accounting/reports/sales/item.vue";
import customerSalesPrices from "../accounting/reports/sales/customerSalesPrices";

//subscriptionsOperations reports
import shippingOperationsByInvoice from "../accounting/reports/shippingOperations/shippingOperationsByInvoice.vue";

//purchases reports
import purchasesByBill from "../accounting/reports/purchases/bill.vue";
import purchasesByItem from "../accounting/reports/purchases/item.vue";

//storesdeposits reports
import storesDepositsByInvoice from "../accounting/reports/storesDeposits/invoice.vue";
import storesDepositsByItem from "../accounting/reports/storesDeposits/item.vue";


//storeswithdrawals reports
import storesWithdrawalsByBill from "../accounting/reports/storesWithdrawals/bill.vue";
import storesWithdrawalsByItem from "../accounting/reports/storesWithdrawals/item.vue";

//storestransfers reports
import storesTransfersByInvoice from "../accounting/reports/storesTransfers/invoice.vue";
import storesTransfersByItem from "../accounting/reports/storesTransfers/item.vue";

//salesReturns reports
import salesReturnsByInvoice from "../accounting/reports/salesReturns/invoice.vue";
import salesReturnsByItem from "../accounting/reports/salesReturns/item.vue";


//projectsDeposits reports
import projectsDepositsByInvoice from "../accounting/reports/projectsDeposits/invoice.vue";
import projectsDepositsByItem from "../accounting/reports/projectsDeposits/item.vue";


//projectsWithdrawals reports
import projectsWithdrawalsByBill from "../accounting/reports/projectsWithdrawals/bill.vue";
import projectsWithdrawalsByItem from "../accounting/reports/projectsWithdrawals/item.vue";


//projectsTransfers reports
import projectsTransfersByInvoice from "../accounting/reports/projectsTransfers/invoice.vue";
import projectsTransfersByItem from "../accounting/reports/projectsTransfers/item.vue";

//purchasesReturns reports
import purchasesReturnsByBill from "../accounting/reports/purchasesReturns/bill.vue";
import purchasesReturnsByItem from "../accounting/reports/purchasesReturns/item.vue";

//salesOrders reports
import salesOrdersByInvoice from "../accounting/reports/salesOrders/invoice.vue";
import salesOrdersByItem from "../accounting/reports/salesOrders/item.vue";

//purchasesOrders reports
import purchasesOrdersByBill from "../accounting/reports/purchasesOrders/bill.vue";
import purchasesOrdersByItem from "../accounting/reports/purchasesOrders/item.vue";

//payments reports
import paymentHistory from "../accounting/reports/payments/paymentHistory.vue";

import purchasePaymentHistory from "../accounting/reports/payments/purchasePaymentHistory.vue";

import safeDepositHistory from "../accounting/reports/safesDeposits/safeDepositHistory.vue";
import safeWithdrawalHistory from "../accounting/reports/safesWithdrawals/safeWithdrawalHistory.vue";
import safeTransferHistory from "../accounting/reports/safesTransfers/safeTransferHistory.vue";


//cheques reports
import chequesHistory from "../accounting/reports/cheques/chequesHistory.vue";
import purchaseChequesHistory from "../accounting/reports/cheques/purchaseChequesHistory.vue";

//discounts reports
import discountsHistory from "../accounting/reports/discounts/discountsHistory.vue";
import purchaseDiscountsHistory from "../accounting/reports/discounts/purchaseDiscountsHistory.vue";

//installments reports
import installmentsHistory from "../accounting/reports/installments/installmentsHistory.vue";
import installmentsDue from "../accounting/reports/installments/installmentsDue.vue";
import installmentsPaymentsHistory from "../accounting/reports/installments/installmentsPaymentsHistory.vue";

//expenses reports
import expenseHistory from "../accounting/reports/expenses/history.vue";
import expenseByCategory from "../accounting/reports/expenses/category.vue";

//employees report
//import accountStatement        from "../accounting/reports/employees/accountStatement.vue";

//accounting reports
import profitsByInvoices from "../accounting/accounting/profitsByInvoices.vue";
import profitsAndLosses from "../accounting/accounting/profitsAndLosses.vue";
import balanceSheet from "../accounting/accounting/balanceSheet.vue";
import dailyRestrictions from "../accounting/accounting/dailyRestrictions.vue";

// productDetails reports
import productDetailsAccounts from "../accounting/reports/products/detailsAccounts.vue";

//inactiveProducts
import inactiveProducts from "../accounting/reports/products/inactiveProducts.vue";


//projects reports
import projectsTotalAccounts from "../accounting/reports/projects/totalAccounts.vue";
import projectsDetailsAccounts from "../accounting/reports/projects/detailsAccounts.vue";
import projectsBalance from "../accounting/reports/projects/balance.vue";
import projectsOverview from "../accounting/reports/projects/overview.vue"
import projectMovement from "../accounting/reports/projects/projectMovement.vue"

import partnerDepositsHistory from "../accounting/reports/partnersPayments/partnerDepositsHistory.vue"
import partnerWithdrawalsHistory from "../accounting/reports/partnersPayments/partnerWithdrawalsHistory.vue"

import accountTransactionPaymentsRecord from "../accounting/reports/accountsOperations/accountTransactionPaymentsRecord.vue"
import recordWithdrawalsAccountsOperations from "../accounting/reports/accountsOperations/recordWithdrawalsAccountsOperations.vue"

//settings
import options from "../accounting/options.vue";



/*
import trialBalance            from "../accounting/accounting/trialBalance.vue";
import balanceSheet            from "../accounting/accounting/balanceSheet.vue";
import generalReferenceBook    from "../accounting/accounting/generalReferenceBook.vue";
import journalEntries          from "../accounting/accounting/journalEntries.vue";
*/
const routes = [
  {
    path: "/",
    component: container,
    redirect: "/accounting",
    children: [
      { path: "/", name: "main", component: accounting },
      { path: "/profile", name: "profile", component: usersForm },
      { path: "/backup", name: "backup", component: backup},

      ...saless,
      ...purchase,
      ...safes,
      ...stores,
      ...employees,
      ...partners,
      ...accounts,
      ...projects,
      ...subscriptions,
      ...cheques,
      ...discounts,
      ...installments,
      ...manufacturing,
      ...shippings,
      ...accountsTransfer,
      ...freeprinted,
      ...pointOfSales,


      { path: "capitals", name: "capitals", component: capitalsIndex },
      {
        path: "capitals/create",
        name: "capitalsCreate",
        component: capitalsForm,
      },
      {
        path: "capitals/:id/edit",
        name: "capitalsEdit",
        component: capitalsForm,
      },
      { path: "capitals/:id", name: "capitalsShow", component: capitalsShow },

      { path: "units", name: "units", component: unitsIndex },
      { path: "units/create", name: "unitsCreate", component: unitsForm },
      { path: "units/:id/edit", name: "unitsEdit", component: unitsForm },

      { path: "unitsCoefficients", name: "unitsCoefficients", component: unitsCoefficientsIndex },
      { path: "unitsCoefficients/create", name: "unitsCoefficientsCreate", component: unitsCoefficientsForm },
      { path: "unitsCoefficients/:id/edit", name: "unitsCoefficientsEdit", component: unitsCoefficientsForm },

      { path: "sections", name: "sections", component: sectionsIndex },
      {
        path: "sections/create",
        name: "sectionsCreate",
        component: sectionsForm,
      },
      {
        path: "sections/:id/edit",
        name: "sectionsEdit",
        component: sectionsForm,
      },
      { path: "sectionsAccounts", name: "sectionsAccounts", component: sectionsAccountsIndex },
      { path: "sectionsAccounts/create", name: "sectionsAccountsCreate",component: sectionsAccountsForm },
      { path: "sectionsAccounts/:id/edit", name: "sectionsAccountsEdit", component: sectionsAccountsForm },
      { path: "signatures", name: "signatures", component: signaturesIndex },
      {
        path: "signatures/create",
        name: "signaturesCreate",
        component: signaturesForm,
      },
      {
        path: "signatures/:id/edit",
        name: "signaturesEdit",
        component: signaturesForm,
      },
      { path: "priceLists", name: "priceLists", component: priceListsIndex },
      {
        path: "priceLists/create",
        name: "priceListsCreate",
        component: priceListsForm,
      },
      {
        path: "priceLists/:id/edit",
        name: "priceListsEdit",
        component: priceListsForm,
      },

      { path: "details", name: "details", component: detailsIndex },
      { path: "details/create", name: "detailsCreate", component: detailsForm },
      { path: "details/:id/edit", name: "detailsEdit", component: detailsForm },

      {
        path: "productOptions",
        name: "productOptions",
        component: productOptionsIndex,
      },
      {
        path: "productOptions/create",
        name: "productOptionsCreate",
        component: productOptionsFrom,
      },
      {
        path: "productOptions/:id/edit",
        name: "productOptionsEdit",
        component: productOptionsFrom,
      },

      { path: "taxes", name: "taxes", component: taxesIndex },
      { path: "taxes/create", name: "taxesCreate", component: taxesForm },
      { path: "taxes/:id/edit", name: "taxesEdit", component: taxesForm },

      { path: "discounts", name: "discounts", component: discountsIndex },
      {
        path: "discounts/create",
        name: "discountsCreate",
        component: discountsForm,
      },
      {
        path: "discounts/:id/edit",
        name: "discountsEdit",
        component: discountsForm,
      },

      { path: "users", name: "users", component: usersIndex },
      { path: "users/create", name: "usersCreate", component: usersForm },
      { path: "users/:id/edit", name: "usersEdit", component: usersForm },

      { path: "roles", name: "roles", component: rolesIndex },
      { path: "roles/create", name: "rolesCreate", component: rolesForm },
      { path: "roles/:id/edit", name: "rolesEdit", component: rolesForm },

      { path: "companies", name: "companies", component: companiesIndex },
      {
        path: "companies/create",
        name: "companiesCreate",
        component: companiesForm,
      },
      {
        path: "companies/:id/edit",
        name: "companiesEdit",
        component: companiesForm,
      },

      {
        path: "invoiceGroups",
        name: "invoiceGroups",
        component: invoiceGroupsIndex,
      },
      {
        path: "invoiceGroups/create",
        name: "invoiceGroupsCreate",
        component: invoiceGroupsForm,
      },
      {
        path: "invoiceGroups/:id/edit",
        name: "invoiceGroupsEdit",
        component: invoiceGroupsForm,
      },
      {
        path: "invoiceStatus",
        name: "invoiceStatus",
        component: invoiceStatusIndex,
      },
      {
        path: "invoiceStatus/create",
        name: "invoiceStatusCreate",
        component: invoiceStatusForm,
      },
      {
        path: "invoiceStatus/:id/edit",
        name: "invoiceStatusEdit",
        component: invoiceStatusForm,
      },

      
      { path: "paymentFees", name: "paymentFees", component: paymentFeesIndex },
      {
        path: "paymentFees/create",
        name: "paymentFeesCreate",
        component: paymentFeesForm,
      },
      {
        path: "paymentFees/:id/edit",
        name: "paymentFeesEdit",
        component: paymentFeesForm,
      },

      {
        path: "expenseSections",
        name: "expenseSections",
        component: expenseSectionsIndex,
      },
      {
        path: "expenseSections/create",
        name: "expenseSectionsCreate",
        component: expenseSectionsForm,
      },
      {
        path: "expenseSections/:id/edit",
        name: "expenseSectionsEdit",
        component: expenseSectionsForm,
      },
      //{path: "expenseSections/:id",name: "expenseSectionsShow",component: expenseSectionsShow},

      {
        path: "PaymentsSections",
        name: "PaymentsSections",
        component: PaymentsSectionsIndex,
      },
      {
        path: "PaymentsSections/create",
        name: "PaymentsSectionsCreate",
        component: PaymentsSectionsForm,
      },
      {
        path: "PaymentsSections/:id/edit",
        name: "PaymentsSectionsEdit",
        component: PaymentsSectionsForm,
      },

      { path: "marketers", name: "marketers", component: marketersIndex },
      {
        path: "marketers/create",
        name: "marketersCreate",
        component: marketersForm,
      },
      {
        path: "marketers/:id/edit",
        name: "marketersEdit",
        component: marketersForm,
      },
     
      { path: "overview", name: "overview", component: overview },
      { path: "sales", name: "sales", component: sales },
      { path: "purchases", name: "purchases", component: purchases },
      { path: "lockers", name: "lockers", component: lockers },
      { path: "stocks", name: "stocks", component: stocks },
      { path: "employee", name: "employee", component: employee },
      { path: "partner", name: "partner", component: partner },
      { path: "accountOperation", name: "accountOperation", component: accountOperation },
      { path: "project", name: "project", component: project },
      { path: "checks", name: "checks", component: checks },
      { path: "discount", name: "discount", component: discount },
      { path: "installment", name: "installment", component: installment },
      { path: "pointOfSale", name: "pointOfSale", component: pointOfSale },
      { path: "shipping", name: "shipping", component: shipping },
      { path: "totalReport", name: "totalReport", component: totalReport },

      // reports router
      { path: "mainReport", name: "mainReport", component: mainReport },
      { path: "pointOfSalesOverview", name: "pointOfSalesOverview", component: pointOfSalesOverview },

      {
        path: "customersTotalAccounts",
        name: "customersTotalAccounts",
        component: customersTotalAccounts,
      },
      {
        path: "customersDetailsAccounts",
        name: "customersDetailsAccounts",
        component: customersDetailsAccounts,
      },
      {
        path: "customersBalance",
        name: "customersBalance",
        component: customersBalance,
      },
      {
        path: "customersGuide",
        name: "customersGuide",
        component: customersGuide,
      },
      {
        path: "customersInactive",
        name: "customersInactive",
        component: customersInactive,
      },
      {
        path: "customersActive",
        name: "customersActive",
        component: customersActive,
      },
      {
        path: "customerTraffic",
        name: "customerTraffic",
        component: customerTraffic,
      },
      {
        path: "salesCollection",
        name: "salesCollection",
        component: salesCollection,
      },
      
      {
        path: "suppliersTotalAccounts",
        name: "suppliersTotalAccounts",
        component: suppliersTotalAccounts,
      },
      {
        path: "suppliersDetailsAccounts",
        name: "suppliersDetailsAccounts",
        component: suppliersDetailsAccounts,
      },
      {
        path: "suppliersBalance",
        name: "suppliersBalance",
        component: suppliersBalance,
      },
      {
        path: "suppliersGuide",
        name: "suppliersGuide",
        component: suppliersGuide,
      },
      {
        path: "suppliersInactive",
        name: "suppliersInactive",
        component: suppliersInactive,
      },
      {
        path: "supplierMovement",
        name: "supplierMovement",
        component: supplierMovement,
      },
      {
        path: "purchasingCollecting",
        name: "purchasingCollecting",
        component: purchasingCollecting,
      },

      {
        path: "partnersTotalAccounts",
        name: "partnersTotalAccounts",
        component: partnersTotalAccounts,
      },
      {
        path: "partnersDetailsAccounts",
        name: "partnersDetailsAccounts",
        component: partnersDetailsAccounts,
      },
      {
        path: "partnersBalance",
        name: "partnersBalance",
        component: partnersBalance,
      },
      {
        path: "partnersGuide",
        name: "partnersGuide",
        component: partnersGuide,
      },
      {
        path: "partnerMovement",
        name: "partnerMovement",
        component: partnerMovement,
      },

      {
        path: "totalAccounts",
        name: "totalAccounts",
        component: totalAccounts,
      },
      {
        path: "accountDetails",
        name: "accountDetails",
        component: accountDetails,
      },
      {
        path: "accountBalances",
        name: "accountBalances",
        component: accountBalances,
      },
      {
        path: "accountsGuide",
        name: "accountsGuide",
        component: accountsGuide,
      },
      {
        path: "movementAccounts",
        name: "movementAccounts",
        component: movementAccounts,
      },


      {
        path: "employeesTotalAccounts",
        name: "employeesTotalAccounts",
        component: employeesTotalAccounts,
      },
      {
        path: "employeesBalance",
        name: "employeesBalance",
        component: employeesBalance,
      },
      {
        path: "employeesGuide",
        name: "employeesGuide",
        component: employeesGuide,
      },
      {
        path: "employeesOperationsDeductions",
        name: "employeesOperationsDeductions",
        component: employeesOperationsDeductions,
      },
      {
        path: "employeesOperationsIncentives",
        name: "employeesOperationsIncentives",
        component: employeesOperationsIncentives,
      },
      {
        path: "employeesOperationsSalaries",
        name: "employeesOperationsSalaries",
        component: employeesOperationsSalaries,
      },
      {
        path: "employeesOperationsWithdrawals",
        name: "employeesOperationsWithdrawals",
        component: employeesOperationsWithdrawals,
      },
      {
        path: "safesAccounts",
        name: "safesAccounts",
        component: safesAccounts,
      },
      {
        path: "safersBalance",
        name: "safersBalance",
        component: safersBalance,
      },
      {
        path: "safesMove",
        name: "safesMove",
        component: safesMove,
      },

      {
        path: "warehouseInventory",
        name: "warehouseInventory",
        component: warehouseInventory,
      },
      {
        path: "ProductsValue",
        name: "ProductsValue",
        component: ProductsValue,
      },
      {
        path: "productAlerts",
        name: "productAlerts",
        component: productAlerts,
      },

      {
        path: "salesByInvoice",
        name: "salesByInvoice",
        component: salesByInvoice,
      },
      { path: "salesByItem", name: "salesByItem", component: salesByItem },
      { path: "customerSalesPrices", name: "customerSalesPrices", component: customerSalesPrices },
      {
        path: "shippingOperationsByInvoice",
        name: "shippingOperationsByInvoice",
        component: shippingOperationsByInvoice,
      },
      {
        path: "purchasesByBill",
        name: "purchasesByBill",
        component: purchasesByBill,
      },
      {
        path: "purchasesByItem",
        name: "purchasesByItem",
        component: purchasesByItem,
      },

      {
        path: "projectsDepositsByInvoice",
        name: "projectsDepositsByInvoice",
        component: projectsDepositsByInvoice,
      },
      { path: "projectsDepositsByItem", name: "projectsDepositsByItem", component: projectsDepositsByItem },

      {
        path: "projectsWithdrawalsByBill",
        name: "projectsWithdrawalsByBill",
        component: projectsWithdrawalsByBill,
      },
      {
        path: "projectsWithdrawalsByItem",
        name: "projectsWithdrawalsByItem",
        component: projectsWithdrawalsByItem,
      },

      {
        path: "projectsTransfersByInvoice",
        name: "projectsTransfersByInvoice",
        component: projectsTransfersByInvoice,
      },
      { path: "projectsTransfersByItem", name: "projectsTransfersByItem", component: projectsTransfersByItem },

      // salesReturns routes
      {
        path: "salesReturnsByInvoice",
        name: "salesReturnsByInvoice",
        component: salesReturnsByInvoice,
      },
      {
        path: "salesReturnsByItem",
        name: "salesReturnsByItem",
        component: salesReturnsByItem,
      },

      // purchasesReturns routes
      {
        path: "purchasesReturnsByBill",
        name: "purchasesReturnsByBill",
        component: purchasesReturnsByBill,
      },
      {
        path: "purchasesReturnsByItem",
        name: "purchasesReturnsByItem",
        component: purchasesReturnsByItem,
      },

      // salesOrders routes
      {
        path: "salesOrdersByInvoice",
        name: "salesOrdersByInvoice",
        component: salesOrdersByInvoice,
      },
      {
        path: "salesOrdersByItem",
        name: "salesOrdersByItem",
        component: salesOrdersByItem,
      },



      {
        path: "storesDepositsByInvoice",
        name: "storesDepositsByInvoice",
        component: storesDepositsByInvoice,
      },
      {
        path: "storesDepositsByItem",
        name: "storesDepositsByItem",
        component: storesDepositsByItem,
      },




      {
        path: "storesWithdrawalsByBill",
        name: "storesWithdrawalsByBill",
        component: storesWithdrawalsByBill,
      },
      {
        path: "storesWithdrawalsByItem",
        name: "storesWithdrawalsByItem",
        component: storesWithdrawalsByItem,
      },



      {
        path: "storesTransfersByInvoice",
        name: "storesTransfersByInvoice",
        component: storesTransfersByInvoice,
      },
      {
        path: "storesTransfersByItem",
        name: "storesTransfersByItem",
        component: storesTransfersByItem,
      },

      // purchasesOrders routes
      {
        path: "purchasesOrdersByBill",
        name: "purchasesOrdersByBill",
        component: purchasesOrdersByBill,
      },
      {
        path: "purchasesOrdersByItem",
        name: "purchasesOrdersByItem",
        component: purchasesOrdersByItem,
      },

      {
        path: "paymentHistory",
        name: "paymentHistory",
        component: paymentHistory,
      },
      {
        path: "purchasePaymentHistory",
        name: "purchasePaymentHistory",
        component: purchasePaymentHistory,
      },


      {
        path: "safeDepositHistory",
        name: "safeDepositHistory",
        component: safeDepositHistory,
      },
      {
        path: "safeWithdrawalHistory",
        name: "safeWithdrawalHistory",
        component: safeWithdrawalHistory,
      },
      {
        path: "safeTransferHistory",
        name: "safeTransferHistory",
        component: safeTransferHistory,
      },

      {
        path: "chequesHistory",
        name: "chequesHistory",
        component: chequesHistory,
      },
      {
        path: "purchaseChequesHistory",
        name: "purchaseChequesHistory",
        component: purchaseChequesHistory,
      },

      // discounts routes
      {
        path: "discountsHistory",
        name: "discountsHistory",
        component: discountsHistory,
      },
      {
        path: "purchaseDiscountsHistory",
        name: "purchaseDiscountsHistory",
        component: purchaseDiscountsHistory,
      },

      {
        path: "installmentsHistory",
        name: "installmentsHistory",
        component: installmentsHistory,
      },
      {
        path: "installmentsDue",
        name: "installmentsDue",
        component: installmentsDue,
      },
      {
        path: "installmentsPaymentsHistory",
        name: "installmentsPaymentsHistory",
        component: installmentsPaymentsHistory,
      },
      {
        path: "expenseHistory",
        name: "expenseHistory",
        component: expenseHistory,
      },
      {
        path: "expenseByCategory",
        name: "expenseByCategory",
        component: expenseByCategory,
      },

      {
        path: "partnerDepositsHistory",
        name: "partnerDepositsHistory",
        component: partnerDepositsHistory,
      },
      
      {
        path: "partnerWithdrawalsHistory",
        name: "partnerWithdrawalsHistory",
        component: partnerWithdrawalsHistory,
      },

      {
        path: "accountTransactionPaymentsRecord",
        name: "accountTransactionPaymentsRecord",
        component: accountTransactionPaymentsRecord,
      },
      
      {
        path: "recordWithdrawalsAccountsOperations",
        name: "recordWithdrawalsAccountsOperations",
        component: recordWithdrawalsAccountsOperations,
      },

      //  {path: "employeeAccountStatement",name: "employeeAccountStatement",component: accountStatement},

      {
        path: "profitsByInvoices",
        name: "profitsByInvoices",
        component: profitsByInvoices,
      },
      {
        path: "profitsAndLosses",
        name: "profitsAndLosses",
        component: profitsAndLosses,
      },
      { path: "balanceSheet", name: "balanceSheet", component: balanceSheet },
      { path: "dailyRestrictions", name: "dailyRestrictions", component: dailyRestrictions },

      {
        path: "productDetailsAccounts",
        name: "productDetailsAccounts",
        component: productDetailsAccounts,
      },
      {
        path: "inactiveProducts",
        name: "inactiveProducts",
        component: inactiveProducts,
      },

      {
        path: "projectsTotalAccounts",
        name: "projectsTotalAccounts",
        component: projectsTotalAccounts,
      },
      {
        path: "projectsDetailsAccounts",
        name: "projectsDetailsAccounts",
        component: projectsDetailsAccounts,
      },
      {
        path: "projectsBalance",
        name: "projectsBalance",
        component: projectsBalance,
      },
      {
        path: "projectsOverview",
        name: "projectsOverview",
        component: projectsOverview,
      },
      {
        path: "projectMovement",
        name: "projectMovement",
        component: projectMovement,
      },

      {
        path: "applications",
        name: "applications",
        component: applicationsIndex,
      },
      {
        path: "applications/whatsapp",
        name: "whatsappApplications",
        component: applicationsWhatsapp,
      },
      {
        path: "applications/messages",
        name: "applicationsmessages",
        component: applicationsmessages,
      },
      //settings
      {
        path: "options",
        name: "options",
        component: options,
      }

    ],
  },
  { path: "/login", component: login },
  { path: "*", component: NotFound },
];

export default routes;
