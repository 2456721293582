var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('reports.accounts.reports'),"description":_vm.$t('reports.overview.customerAccountDetails')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('reports.accounts.reportOptions'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.customer'),"plaseholder":_vm.$t('reports.overview.chooseCustomer'),"show":'name',"refrance":'id',"value":_vm.params.customer_id,"values":_vm.customers},model:{value:(_vm.params.customer_id),callback:function ($$v) {_vm.$set(_vm.params, "customer_id", $$v)},expression:"params.customer_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('reports.overview.customerAccountDetails'),"details":[
            {title: _vm.$t('reports.overview.customerName'), value : _vm.customerName }
          ]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 8, value: _vm.$t('reports.overview.openingBalance') },
            {
              colspan: 1,
              value: _vm.startBalance,
            } ],"emptyTableText":_vm.$t('reports.accounts.thereNoDataTheMoment'),"emptyTableSubText":_vm.$t('reports.accounts.tryAddingSomeAndTryAgain'),"cloumns":[
            { column: 'code', title: _vm.$t('reports.overview.code'), type: 'reportCode' },
            { column: 'date', title: _vm.$t('reports.overview.date'), type: 'text' },
            { column: 'type', title: _vm.$t('reports.overview.type'), type: 'reportType' },
            { column: 'statement', title: _vm.$t('reports.overview.statement'), type: 'statement' },
            { column: 'quantity', title: _vm.$t('reports.overview.quantity'), type: 'textOrEmpty' },
            {
              column: 'price',
              title: _vm.$t('reports.overview.price'),
              type: 'textOrEmpty',
            },
            {
              column: 'debit',
              title: _vm.$t('reports.overview.debtor'),
              type: 'textOrEmpty',
            },
            {
              column: 'creditor',
              title: _vm.$t('reports.overview.creditor'),
              type: 'textOrEmpty',
            },
            {
              column: 'new_balance',
              title: _vm.$t('reports.overview.balance'),
              type: 'text',
            } ],"footer":[
            { colspan: 6, value: _vm.$t('reports.overview.theFinalBalance') },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            {
              colspan: 1,
              value: _vm.endBalance,
            } ],"tafqeet":_vm.tafqeet}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }