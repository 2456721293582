<template>
    <div class="modal fade show">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-card card">
                    <div class="card-header">

                        <!-- Title -->
                        <h4 class="card-header-title">
                            اضافة قسم
                        </h4>

                        <!-- Close -->
                        <button type="button" class="close" @click="colse()">
                            <span aria-hidden="true">×</span>
                        </button>

                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label class="col-sm-12">
                                        التصميف الأساسي
                                    </label>
                                    <select class="form-control"   :class="{ 'is-invalid' : hasErorr}">
                                        <option value="0">لا يوجد</option>
                                        <optgroup label="التصميفات">
                                            <option v-for="section in sections" :key="section.id" :value="section.id">{{section.name}}</option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label class="col-sm-12">
                                        اسم التصنيف
                                    </label>
                                    <input type="number" class="form-control col-md-12" v-model="section.rate">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer card-footer-boxed">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-auto">


                            </div>
                            <div class="col-auto">

                                <!-- Link -->
                                <button class="btn btn-outline-danger" @click="colse()">
                                    <i class="far fa-times"></i>
                                    الغاء
                                </button>
                                <button @click="createَQuotation()" class="btn btn-dark">
                                    <i class="far fa-save"></i>
                                    انشاء
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    
    export default {
        data() {
            return {
                section: {},
                sections: {},
                errors: []
            }
        },
        methods: {
            getSections() {
                axios
                    .get(this.$linkGnirator('/sectionsItems'))
                    .then(response => {
                        this.sections = response.data;
                    });
            },
            createَQuotation() {
                axios.post(this.$linkGnirator('/taxes'), this.tax)
                    .then(response => {
                        this.colse();
                        return response;
                        //this.$parent.taxes.unshift(response.data);
                    });
            },
            colse() {
                this.$parent.sectionModel = false;
                this.$parent.model = 0;
            }
        },
         mounted() {
            this.getSections();
        }
    }

</script>
