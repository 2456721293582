<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="'التقارير'"
          :description="'ايداع المشروعات بالفاتورة.'"
        />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i> خيارات التقرير
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              
              <dateFilter class="col-md-3" />

              <searchInput
                class="col-md-3"
                title="المستخدم"
                plaseholder="اختر مستخدم"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="this.users"
              />
              <searchInput
                class="col-md-3"
                title="المشروع"
                plaseholder="اختر مشروع"
                :show="'name'"
                :refrance="'id'"
                v-model="params.project_id"
                :value="params.project_id"
                :values="$database.projects"
              />
              <searchInput
                class="col-md-3"
                title="المخزن"
                plaseholder="اختر مخزن"
                :show="'name'"
                :refrance="'id'"
                v-model="params.store_id"
                :value="params.store_id"
                :values="$database.stores"
              />
              

              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">
          <reportHeader 
            title="تقرير ايداع المشروعات بالفاتورة" 
            :details="[
              {title: 'اسم المستخدم', value : userName },
              {title: 'المخزن', value : storeName },
              {title: 'المشروع', value : projectName }
            ]"
          />
          <reportTable
            :emptyTableText="'لايوجد بيانات حاليا'"
            :emptyTableSubText="'جرب اضافة البعض واعد المحاولة'"
            :cloumns="[
              { column: 'code', link: '/projectsDeposits', title: 'الكود', type:'reportMainLink', sort: true},
              {
                column: 'date',
                title: 'التاريخ',
                type: 'text',
                sort: true,
                supData: true,
              },
              {column: 'project_id',    title: 'المشروع', type: 'link', to:'project', sort: true, link: true},
              {
                column: 'user',
                title: 'المستخدم',
                type: 'text',
                sort: true,
                supData: true,
              },
              
              {
                column: 'total',
                title: 'المجموع',
                type: 'text',
                sort: true,
              },
            ]"
            :footer="[
              { colspan: 1, value: 'المجموع' },
              { colspan: 3, value: '' },
              { colspan: 1, value: total},
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import reportHeader from "../../elements/reports/reportHeader.vue";
import reportTable from "../../elements/reports/reportTable.vue";
import indexHeader from "../../elements/index/indexHeader.vue";
import dateFilter from "../../elements/reports/dateFilter.vue";
import searchInput from "../../elements/reports/searchInput.vue";
import actionButtons from "../../elements/reports/actionButtons.vue";

export default {
  data() {
    return {
      path: "/reports/projectsDeposits/invoices",
      items: [],
      loading: 1,
      invoicesCount: 0,
      total: 0,
      params: {
        store_id: "",
        user_id: "",
        project_id: "",
        dateFilter: 2,
        startDate: '',
        endDate: ''
      },

      excel: {
        fileName: 'prject beposits by invoice',
        reportData: '[]'
      },

      storeName:"",
      projectName:"",
      userName:"",

      users: [],

      searchStartDate: "",
      searchEndDate: "",
    };
  },
  methods: {
    getData() {
      this.$setParams(this.params);
      this.loading = 1;
      this.totalPages = 0;
      this.items = [];
      axios
        .get(this.$linkGnirator(this.path), { params: this.params })
        .then((response) => {
          this.items = response.data;

          this.searchStartDate = this.params.startDate;
          this.searchEndDate = this.params.endDate;

          this.total = 0;
          this.invoicesCount = this.items.length;

          this.items.forEach((item) => {
            this.total += item.total;

            item.total = this.$moneyFormat(item.total)

            var project = this.$database.projects.find((el) => el.id == item.project_id);
            if (project) {item.project = project;}

            var user = this.users.find((el) => el.id == item.user_id);
            if (user) {
              item.user = user.name;
            }

            item.date = this.$dateFormat(item.date)

          });
          this.total = this.$moneyFormat(this.total)

          this.loading = 0;
        });

       var store = this.$database.stores.find((el) => el.id == this.params.store_id);
        if (store) {
          this.storeName = store.name;
        } else{
          this.storeName =""
        }

        var project = this.$database.projects.find((el) => el.id == this.params.project_id);
        if (project) {
          this.projectName = project.name;
        } else{
          this.projectName =""
        }

        var user = this.users.find((el) => el.id == this.params.user_id);
        if (user) {
          this.userName = user.name;
        } else{
          this.userName =""
        }

    },
    getItems() {
      this.$setParams(this.params);
      if (this.params.orderBy) {
        this.items.sort(this.$dynamicSort(this.params));
      }
    },
    getUsers() {
      axios.get(this.$linkGnirator("/usersItems")).then((response) => {
        this.users = response.data;
      });
    },
  },
  watch: {
    items : function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data  = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map(r => [...r.querySelectorAll('th, td')].map(td => td.textContent))
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData);
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams();
    if (typeof this.params.startDate === 'undefined') {
      this.params.startDate = new Date().toISOString('en-US', { timeZone: 'Africa/Cairo' }).slice(0, 10);
    }
    this.getData();
    this.getUsers();
  },
  components: {
    reportHeader,
    reportTable,
    indexHeader,
    dateFilter,
    searchInput,
    actionButtons,
  },
};
</script>
