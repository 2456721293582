var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'كشف حساب الخزينة.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"الخزينة","plaseholder":"اختر الخزينة","show":'name',"refrance":'id',"value":_vm.params.safe_id,"values":_vm.$database.safes},model:{value:(_vm.params.safe_id),callback:function ($$v) {_vm.$set(_vm.params, "safe_id", $$v)},expression:"params.safe_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"كشف حساب الخزينة","details":[
            {title: 'اسم الخزينة', value : _vm.safeName }
          ]}}),_c('reportTable',{attrs:{"header":[
            { colspan: 5, value: 'الرصيد الأفتتاحي' },
            { colspan: 2, value: '' },
            { colspan: 1, value: _vm.startBalance } ],"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            { column: 'code',        title: 'الكود',    type: 'reportCode' },
            { column: 'date',        title: 'التاريخ',  type: 'text' },
            { column: 'type',        title: 'العملية',  type: 'reportType' },
            { column: 'client_type', title: 'الحساب',   type: 'accountLink' },
            { column: 'statement',   title: 'البيان',   type: 'statement' },
            { column: 'creditor',    title: 'مدين',     type: 'textOrEmpty' },
            { column: 'debit',       title: 'دائن',     type: 'textOrEmpty' },
            { column: 'new_balance', title: 'الرصيد',   type: 'text' } ],"footer":[
            { colspan: 5, value: 'الرصيد النهائي' },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            { colspan: 1, value: _vm.endBalance } ],"tafqeet":_vm.tafqeet}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])}]

export { render, staticRenderFns }