var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('reports.accounts.reports'),"description":_vm.$t('reports.overview.dailyRestrictionsDetails')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('reports.accounts.reportOptions'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('reports.overview.dailyRestrictionsDetails'),"details":[]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('reports.accounts.thereNoDataTheMoment'),"emptyTableSubText":_vm.$t('reports.accounts.tryAddingSomeAndTryAgain'),"cloumns":[
            { column: 'code', title: _vm.$t('reports.overview.code'), type: 'reportCode' },
            { column: 'type', title: 'العملية', type: 'reportType' },
            { column: 'date', title: _vm.$t('reports.overview.date'), type: 'text' },
            {column: 'debit',title: _vm.$t('reports.overview.debtor'),type: 'textOrEmpty',},
            { column: 'creditor',title: _vm.$t('reports.overview.creditor'),type: 'textOrEmpty',},
            { column: 'statement', title: _vm.$t('reports.overview.statement'), type: 'statement' },
            { column: 'account', title: _vm.$t('reports.overview.accountName'), type: 'account' } ],"footer":[
            { colspan: 3, value: 'الإجمالي' },
            { colspan: 1, value: _vm.debit },
            { colspan: 1, value: _vm.creditor },
            { colspan: 1, value: '' },
            { colspan: 1, value: _vm.total } ]}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }