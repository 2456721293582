<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('reports.overview.Reports')"
          :description="$t('reports.overview.PublicGlance')"
        />
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-edit"></i>
              {{ $t("reports.overview.ReportOptions") }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <dateFilter class="col-md-3" />
              <searchInput
                class="col-md-3"
                :title="$t('reports.overview.User')"
                :plaseholder="$t('reports.overview.ChooseAUser')"
                v-model="params.user_id"
                :show="'name'"
                :refrance="'id'"
                :value="params.user_id"
                :values="this.users"
              />
              <searchInput
                class="col-md-3"
                :title="$t('reports.overview.Project')"
                :plaseholder="$t('reports.overview.ChooseAProject')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.project_id"
                :value="params.project_id"
                :values="$database.projects"
              />
              <searchInput
                class="col-md-3"
                :title="$t('reports.overview.PointOfSale')"
                :plaseholder="$t('reports.overview.ChooseASellingPoint')"
                :show="'name'"
                :refrance="'id'"
                v-model="params.point_of_sale_id"
                :value="params.point_of_sale_id"
                :values="$database.pointOfSales"
              />

              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ $t("reports.overview.SessionNo") }}</label>
                  <input
                    type="text"
                    v-model="params.session_number"
                    class="form-control"
                    :placeholder="$t('reports.overview.EnterTheSessionNumber')"
                  />
                </div>
              </div>
              <actionButtons class="col-md-3" />
            </div>
          </div>
        </div>
        <div id="reportShow">

          <reportHeader
            :title="$t('reports.overview.PublicGlance')"
            :details="[
              { title: $t('reports.overview.Username'), value: userName },
              { title: $t('reports.overview.PointOfSale'), value: pointOfSaleName },
              { title: $t('reports.overview.SessionNo'), value: sessionNumber },
            ]"
          />
          
          <div class="card">
            <div class="table-responsive">
              <table class="table table-sm table-nowrap card-table table-hover">
                <tbody class="list" v-if="loading != 1">
                  <tr
                    v-if="
                      totalInvoices == 0 &&
                      totalBills == 0 &&
                      totalSalesReturns == 0 &&
                      totalPurchaseReturns == 0 &&
                      totalSalesPayments == 0 &&
                      totalPurchasePayments == 0 &&
                      totalSalesCheques == 0 &&
                      totalPurchaseCheques == 0 &&
                      totalSalesDiscounts == 0 &&
                      totalPurchaseDiscounts == 0 &&
                      totalExpenses == 0
                    "
                  >
                    <td colspan="20" class="empty-table">
                      <i class="fas fa-inbox d-block"></i
                      >{{ $t("reports.overview.ThereIsNoDataRightNow") }}
                      <p>{{ $t("reports.overview.TryAddingSomeAndTryAgain") }}</p>
                    </td>
                  </tr>

                  <tr v-if="totalInvoices != 0">
                    <td>
                      <strong>{{ $t("reports.overview.TotalSles") }}</strong>
                    </td>
                    <td>{{ $moneyFormat(totalInvoices) }} </td>
                  </tr>

                  <tr v-if="totalBills != 0">
                    <td>
                      <strong>{{ $t("reports.overview.TotalPurchases") }}</strong>
                    </td>
                    <td>{{ $moneyFormat(totalBills) }} </td>
                  </tr>

                  <tr v-if="totalSalesReturns != 0">
                    <td>
                      <strong>{{
                        $t("reports.overview.TotalReturnSales")
                      }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(totalSalesReturns) }}
                    </td>
                  </tr>

                  <tr v-if="totalPurchaseReturns != 0">
                    <td>
                      <strong>{{
                        $t("reports.overview.TotalPurchaseReturns")
                      }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(totalPurchaseReturns) }}
                      
                    </td>
                  </tr>

                  <tr v-if="totalSalesPayments != 0">
                    <td>
                      <strong>{{
                        $t("reports.overview.TotalsalesPayments")
                      }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(totalSalesPayments) }}
                      
                    </td>
                  </tr>

                  <tr v-if="totalPurchasePayments != 0">
                    <td>
                      <strong>{{
                        $t("reports.overview.TotalPurchasePayments")
                      }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(totalPurchasePayments) }}
                      
                    </td>
                  </tr>

                  <tr v-if="totalSalesCheques != 0">
                    <td>
                      <strong>{{
                        $t("reports.overview.TotalSalesCheques")
                      }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(totalSalesCheques) }}
                      
                    </td>
                  </tr>

                  <tr v-if="totalPurchaseCheques != 0">
                    <td>
                      <strong>{{
                        $t("reports.overview.TotalPurchaseCheques")
                      }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(totalPurchaseCheques) }}
                      
                    </td>
                  </tr>

                  <tr v-if="totalSalesDiscounts != 0">
                    <td>
                      <strong>{{
                        $t("reports.overview.TotalSalesDiscounts")
                      }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(totalSalesDiscounts) }}
                      
                    </td>
                  </tr>

                  <tr v-if="totalPurchaseDiscounts != 0">
                    <td>
                      <strong>{{
                        $t("reports.overview.TotalPurchaseDiscounts")
                      }}</strong>
                    </td>
                    <td>
                      {{ $moneyFormat(totalPurchaseDiscounts) }}
                      
                    </td>
                  </tr>

                  <tr v-if="totalExpenses != 0">
                    <td>
                      <strong>{{ $t("reports.overview.expenses") }}</strong>
                    </td>
                    <td>{{ $moneyFormat(totalExpenses) }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import reportHeader from "../elements/reports/reportHeader.vue";
import indexHeader from "../elements/index/indexHeader.vue";
import dateFilter from "../elements/reports/dateFilter.vue";
import searchInput from "../elements/reports/searchInput.vue";
import actionButtons from "../elements/reports/actionButtons.vue";

export default {
  data() {
    return {
      path: "/reports/overview",

      invoices: [],
      salesReturns: [],
      bills: [],
      purchasesReturns: [],

      salesPayments: [],
      purchasePayments: [],
      salesCheques: [],
      purchaseCheques: [],
      salesDiscounts: [],
      purchaseDiscounts: [],

      expenses: [],

      totalInvoices: 0,
      totalBills: 0,
      totalSalesReturns: 0,
      totalPurchaseReturns: 0,
      totalSalesPayments: 0,
      totalPurchasePayments: 0,
      totalSalesCheques: 0,
      totalPurchaseCheques: 0,
      totalSalesDiscounts: 0,
      totalPurchaseDiscounts: 0,
      totalExpenses: 0,

      loading: 0,

      params: {
        store_id: "",
        user_id: "",
        point_of_sale_id: "",
        session_number: "",
        dateFilter: 2,
        startDate: "",
        endDate: "",
      },

      excel: {
        fileName: 'overview',
        reportData: '[]'
      },

      users: [],

      searchStartDate: "",
      searchEndDate: "",

      storeName: "",
      userName: "",
      pointOfSaleName: "",
      sessionNumber: "",
    };
  },
  methods: {
    getData() {
      this.loading = 1;

      this.invoices = [];
      this.salesReturns = [];
      this.bills = [];
      this.purchasesReturns = [];

      this.salesPayments = [];
      this.purchasePayments = [];
      this.salesCheques = [];
      this.purchaseCheques = [];
      this.salesDiscounts = [];
      this.purchaseDiscounts = [];

      this.expenses = [];

      this.totalInvoices = 0;
      this.totalSalesReturns = 0;
      this.totalBills = 0;
      this.totalPurchasesReturns = 0;

      this.totalSalesPayments = 0;
      this.totalPurchasePayments = 0;
      this.totalSalesCheques = 0;
      this.totalPurchaseCheques = 0;
      this.totalSalesDiscounts = 0;
      this.totalPurchaseDiscounts = 0;

      this.totalExpenses = 0;
      axios
        .get(this.$linkGnirator(this.path), { params: this.params })
        .then((response) => {
          this.invoices = response.data.invoices;
          this.salesReturns = response.data.salesReturns;
          this.bills = response.data.bills;
          this.purchasesReturns = response.data.purchasesReturns;

          this.salesPayments = response.data.salesPayments;
          this.purchasePayments = response.data.purchasePayments;
          this.salesCheques = response.data.salesCheques;
          this.purchaseCheqes = response.data.purchaseCheques;
          this.salesDiscounts = response.data.salesDiscounts;
          this.purchaseDiscounts = response.data.purchaseDiscounts;

          this.expenses = response.data.expenses;

          this.invoices.forEach((item) => {
            this.totalInvoices += item.total;
          });

          this.salesReturns.forEach((item) => {
            this.totalSalesReturns += item.total;
          });

          this.bills.forEach((item) => {
            this.totalBills += item.total;
          });

          this.purchasesReturns.forEach((item) => {
            this.totalPurchasesReturns += item.total;
          });

          this.salesPayments.forEach((item) => {
            this.totalSalesPayments += item.cost;
          });

          this.purchasePayments.forEach((item) => {
            this.totalPurchasePayments += item.cost;
          });

          this.salesCheques.forEach((item) => {
            this.totalSalesCheques += item.cost;
          });

          this.purchaseCheques.forEach((item) => {
            this.totalPurchaseCheques += item.cost;
          });

          this.salesDiscounts.forEach((item) => {
            this.totalSalesDiscounts += item.cost;
          });

          this.purchaseDiscounts.forEach((item) => {
            this.totalPurchaseDiscounts += item.cost;
          });

          this.expenses.forEach((item) => {
            this.totalExpenses += item.cost;
          });

          var store = this.$database.stores.find(
            (el) => el.id == this.params.store_id
          );
          if (store) {
            this.storeName = store.name;
          } else {
            this.storeName = "";
          }

          var user = this.users.find((el) => el.id == this.params.user_id);
          if (user) {
            this.userName = user.name;
          } else {
            this.userName = "";
          }

          this.sessionNumber = this.params.session_number;

          var pointOfSale = this.$database.pointOfSales.find(
            (el) => el.id == this.params.point_of_sale_id
          );
          if (pointOfSale) {
            this.pointOfSaleName = pointOfSale.name;
          } else {
            this.pointOfSaleName = "";
          }

          this.searchStartDate = this.params.startDate;
          this.searchEndDate = this.params.endDate;

          this.loading = 0;
        });
    },
    
    getUsers() {
      axios.get(this.$linkGnirator("/usersItems")).then((response) => {
        this.users = response.data;
      });
    },
  },
  watch: {
    items : function () {
      var mainElemnt = this
      setTimeout(() => {
        var title = document.querySelector('.report-title').textContent
        var data  = document.querySelector('.report-data').textContent
        var date = document.querySelector('.report-date').textContent
        var header = [title, data, date]
        var table = document.querySelector('table')
        var reportData = [...table.rows].map(r => [...r.querySelectorAll('th, td')].map(td => td.textContent))
        reportData.unshift(header)
        mainElemnt.excel.reportData = JSON.stringify(reportData);
      }, 100)
    }
  },
  mounted() {
    this.params = this.$getParams();
    if (typeof this.params.startDate === "undefined") {
      this.params.startDate = new Date()
        .toISOString("en-US", { timeZone: "Africa/Cairo" })
        .slice(0, 10);
    }
    this.getData();
    this.getUsers();
  },
  components: {
    reportHeader,
    indexHeader,
    dateFilter,
    searchInput,
    actionButtons,
  },
};
</script>
