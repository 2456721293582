const routes =  [

      // customers routes
      { 
        path: "customers", 
        name: "customers",
        component: function () {
          return import( '@/accounting/customers/index.vue')
        }
      },
      {
        path: "customers/create",
        name: "customersCreate",
        component: function () {
          return import( '@/accounting/customers/form.vue')
        }
      },
      {
        path: "customers/:id/edit",
        name: "customersEdit",
        component: function () {
          return import( '@/accounting/customers/form.vue')
        }
      },
      {
        path: "customers/explaination",
        name: "customersExplaination",
        component: function () {
          return import( '@/accounting/customers/explain.vue')
        }
      },
      {
        path: "customers/:id",
        name: "customersShow",
        component: function () {
          return import( '@/accounting/customers/show.vue')
        }
      },
    
      //quotations routs

      { 
        path: "quotations", 
        name: "quotations", 
        component:  function () {
          return import( '@/accounting/quotations/index.vue')
        }
      },
      {
        path: "quotations/create",
        name: "quotationsCreate",
        component:  function () {
          return import( '@/accounting/quotations/form.vue')
        }
      },
      {
        path: "quotations/:id/edit",
        name: "quotationsEdit",
        component:  function () {
          return import( '@/accounting/quotations/form.vue')
        }
      },
      {
        path: "quotations/:id",
        name: "quotationsShow",
        component:  function () {
          return import( '@/accounting/quotations/show.vue')
        }
      },

      //invoice routs
      { 
        path: "invoices", 
        name: "invoices", 
        component:  function () {
          return import( '@/accounting/invoices/index.vue')
        } 
      },
      {
        path: "invoices/create",
        name: "invoicesCreate",
        component:  function () {
          return import( '@/accounting/invoices/form.vue')
        } 
      },
      {
        path: "invoices/:id/edit",
        name: "invoicesEdit",
        component:  function () {
          return import( '@/accounting/invoices/form.vue')
        } 
      },
      { 
        path: "invoices/:id", 
        name: "invoicesShow", 
        component:  function () {
          return import( '@/accounting/invoices/show.vue')
        }  
    },

    //saleReturns routs
      { 
        path: "saleReturns", 
        name: "saleReturns", 
        component:  function () {
          return import( '@/accounting/saleReturns/index.vue')
        } 
      },
      {
        path: "saleReturns/create",
        name: "saleReturnsCreate",
        component:  function () {
          return import( '@/accounting/saleReturns/form.vue')
        } 
      },
      {
        path: "saleReturns/:id/edit",
        name: "saleReturnsEdit",
        component:  function () {
          return import( '@/accounting/saleReturns/form.vue')
        } 
      },
      {
        path: "saleReturns/:id",
        name: "saleReturnsShow",
        component:  function () {
          return import( '@/accounting/saleReturns/show.vue')
        } 
      },

      //orders routs

      { 
        path: "orders", 
        name: "orders", 
        component:  function () {
          return import( '@/accounting/orders/index.vue')
        } 
      },
      { 
        path: "orders/create", 
        name: "ordersCreate", 
        component:  function () {
          return import( '@/accounting/orders/form.vue')
        } 
      },
      { 
        path: "orders/:id/edit", 
        name: "ordersEdit", 
        component:  function () {
          return import( '@/accounting/orders/form.vue')
        } 
      },
      { 
        path: "orders/:id", 
        name: "ordersShow", 
        component:  function () {
          return import( '@/accounting/orders/show.vue')
        } 
      },
];

export default routes;
