const routes =  [
    { 
      path: "salesInstallments", 
      name: "salesInstallments",
      component: function () {
        return import( '@/accounting/salesInstallments/index.vue')
      }
    },
    {
      path: "salesInstallments/create",
      name: "salesInstallmentsCreate",
      component: function () {
        return import( '@/accounting/salesInstallments/form.vue')
      }
    },
    {
      path: "salesInstallments/:id/edit",
      name: "salesInstallmentsEdit",
      component: function () {
        return import( '@/accounting/salesInstallments/form.vue')
      }
    },
    {
      path: "salesInstallments/:id",
      name: "salesinstallmentsShow",
      component: function () {
        return import( '@/accounting/salesInstallments/show.vue')
      }
    },

    //sales Installments Payments
    {
      path: "salesInstallmentsPayments",
      name: "salesInstallmentsPayments",
      component: function () {
        return import( '@/accounting/salesInstallmentsPayments/index.vue')
      }
    },
    {
      path: "salesInstallmentsPayments/create",
      name: "salesInstallmentsPaymentsCreate",
      component: function () {
        return import( '@/accounting/salesInstallmentsPayments/form.vue')
      }
    },
    {
      path: "salesInstallmentsPayments/:id/edit",
      name: "salesInstallmentsPaymentsEdit",
      component: function () {
        return import( '@/accounting/salesInstallmentsPayments/form.vue')
      }
    },
    {
      path: "salesInstallmentsPayments/:id",
      name: "salesInstallmentsPaymentsShow",
      component: function () {
        return import( '@/accounting/salesInstallmentsPayments/show.vue')
      }
    },

    //purchaseInstallments
    {
      path: "purchaseInstallments",
      name: "purchaseInstallments",
      component: function () {
        return import( '@/accounting/purchaseInstallments/index.vue')
      }
    },
    {
      path: "purchaseInstallments/create",
      name: "purchaseInstallmentsCreate",
      component: function () {
        return import( '@/accounting/purchaseInstallments/form.vue')
      }
    },
    {
      path: "purchaseInstallments/:id/edit",
      name: "purchaseInstallmentsEdit",
      component: function () {
        return import( '@/accounting/purchaseInstallments/form.vue')
      }
    },
    {
      path: "purchaseInstallments/:id",
      name: "purchaseInstallmentsShow",
      component: function () {
        return import( '@/accounting/purchaseInstallments/show.vue')
      }
    },
];

export default routes;
