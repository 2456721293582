const routes =  [
    { 
      path: "accounts", 
      name: "accounts",
      component: function () {
        return import( '@/accounting/accounts/index.vue')
      }
    },
    {
      path: "accounts/create",
      name: "accountsCreate",
      component: function () {
        return import( '@/accounting/accounts/form.vue')
      }
    },
    {
      path: "accounts/:id/edit",
      name: "accountsEdit",
      component: function () {
        return import( '@/accounting/accounts/form.vue')
      }
    },
    {
      path: "accounts/:id",
      name: "accountsShow",
      component: function () {
        return import( '@/accounting/accounts/show.vue')
      }
    },

    //accounts deposits
    { 
      path: "accountsDeposits",
      name: "accountsDeposits",
      component: function () {
        return import( '@/accounting/accountsDeposits/index.vue')
      }
    },
    {
      path: "accountsDeposits/create",
      name: "accountsDepositsCreate",
      component: function () {
        return import( '@/accounting/accountsDeposits/form.vue')
      }
    },
    {
      path: "accountsDeposits/:id/edit",
      name: "accountsDepositsEdit",
      component: function () {
        return import( '@/accounting/accountsDeposits/form.vue')
      }
    },
    {
      path: "accountsDeposits/:id",
      name: "accountsDepositsShow",
      component: function () {
        return import( '@/accounting/accountsDeposits/show.vue')
      }
    },

    //account Withdrawals
    { 
      path: "accountsWithdrawals",
      name: "accountsWithdrawals",
      component: function () {
        return import( '@/accounting/accountsWithdrawals/index.vue')
      }
    },
    {
      path: "accountsWithdrawals/create",
      name: "accountsWithdrawalsCreate",
      component: function () {
        return import( '@/accounting/accountsWithdrawals/form.vue')
      }
    },
    {
      path: "accountsWithdrawals/:id/edit",
      name: "accountsWithdrawalsEdit",
      component: function () {
        return import( '@/accounting/accountsWithdrawals/form.vue')
      }
    },
    {
      path: "accountsWithdrawals/:id",
      name: "accountsWithdrawalsShow",
      component: function () {
        return import( '@/accounting/accountsWithdrawals/show.vue')
      }
    },
];

export default routes;
