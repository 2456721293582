var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.accountOperations'),"description":_vm.$t('mainReport.FromhereyoucancontrolyouraccountOperationsreports')}})],1),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 34 || el.id == 35 || el.id == 36 || el.id == 37; }
          ) || _vm.$user.admin
        )
        && _vm.$site.accounts_allow 
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.accounts'),"icon":'fa-coins',"reports":[
          {
            name: _vm.$t('mainReport.totalAccounts'),
            link: './totalAccounts',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 37; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.accountDetails'),
            link: '/accountDetails',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 37; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.accountBalances'),
            link: '/accountBalances',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 37; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.accountsGuide'),
            link: '/accountsGuide',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 37; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.movementAccounts'),
            link: '/movementAccounts',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 37; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e(),(
        (
          _vm.$user.role.available_reports.find(
            function (el) { return el.id == 31 || el.id == 32; }
          ) || _vm.$user.admin
        ) &&
        (_vm.$site.accountsDeposits_allow ||
            _vm.$site.accountsWithdrawals_allow 
          )
        )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.accountOperations'),"icon":'fa-hand-holding-usd',"reports":[
          {
            name: _vm.$t('mainReport.accountTransactionPaymentsRecord'),
            link: './accountTransactionPaymentsRecord',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 31; }
              ) || _vm.$user.admin,
          },
          {
            name: _vm.$t('mainReport.recordWithdrawalsAccountsOperations'),
            link: '/recordWithdrawalsAccountsOperations',
            role:
              _vm.$user.role.available_reports.find(
                function (el) { return el.id == 32; }
              ) || _vm.$user.admin,
          } ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }