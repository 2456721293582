const routes =  [
     // salesDiscounts routes

    { 
      path: "salesDiscounts", 
      name: "salesDiscounts",
      component: function () {
        return import( '@/accounting/salesDiscounts/index.vue')
      }
    },
    {
      path: "salesDiscounts/create",
      name: "salesDiscountsCreate",
      component: function () {
        return import( '@/accounting/salesDiscounts/form.vue')
      }
    },
    {
      path: "salesDiscounts/:id/edit",
      name: "salesDiscountsEdit",
      component: function () {
        return import( '@/accounting/salesDiscounts/form.vue')
      }
    },
    {
      path: "salesDiscounts/:id",
      name: "salesDiscountsShow",
      component: function () {
        return import( '@/accounting/salesDiscounts/show.vue')
      }
    },

     // purchaseDiscounts routes
     {
      path: "purchaseDiscounts",
      name: "purchaseDiscounts",
      component: function () {
        return import( '@/accounting/purchaseDiscounts/index.vue')
      }
    },
    {
      path: "purchaseDiscounts/create",
      name: "purchaseDiscountsCreate",
      component: function () {
        return import( '@/accounting/purchaseDiscounts/form.vue')
      }
    },
    {
      path: "purchaseDiscounts/:id/edit",
      name: "purchaseDiscountsEdit",
      component: function () {
        return import( '@/accounting/purchaseDiscounts/form.vue')
      }
    },
    {
      path: "purchaseDiscounts/:id",
      name: "purchaseDiscountsShow",
      component: function () {
        return import( '@/accounting/purchaseDiscounts/show.vue')
      }
    },
];

export default routes;
